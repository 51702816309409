import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import {
    Bar,
    BarChart,
    Legend,
    LabelList,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { theme } from '../../theme';
import { LegendContent } from './LegendContent';

const maleClassName = css({
    fontWeight: 'bold',
});

const femaleClassName = css({
    fill: 'white',
    fontWeight: 'bold',
});

export const AgesPyramidChart = ({ data }) => {
    // males counts are negative numbers so we must get their absolute values
    const maxMales = data.reduce(
        (acc, { male }) => (Math.abs(male) > acc ? Math.abs(male) : acc),
        0,
    );
    const maxFemales = data.reduce(
        (acc, { female }) => (female > acc ? female : acc),
        0,
    );

    // This is maximum count encountered in the data. We'll use it to define the XAxis
    // domain so that the chart is always correctly centered
    let max = maxMales > maxFemales ? maxMales : maxFemales;
    // Add a 10% margin to make the graph prettier
    max = Math.ceil(max + max * 0.01);

    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart
                barCategoryGap="15%"
                barGap="10%"
                data={data}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                stackOffset="sign"
            >
                <Legend
                    verticalAlign="top"
                    height={36}
                    content={LegendContent}
                />
                <XAxis type="number" tick={false} domain={[-max, max]} />
                <YAxis type="category" dataKey="name" />
                <Bar
                    dataKey="male"
                    fill={theme.colors.orange}
                    layout="vertical"
                    stackId="stack"
                >
                    <LabelList
                        className={maleClassName}
                        dataKey="male"
                        formatter={value => Math.abs(value) || ''}
                    />
                </Bar>
                <Bar
                    dataKey="female"
                    fill={theme.colors.blueDark}
                    layout="vertical"
                    stackId="stack"
                >
                    <LabelList
                        className={femaleClassName}
                        dataKey="female"
                        formatter={value => Math.abs(value) || ''}
                    />
                </Bar>
                <ReferenceLine type="vertical" value={0} stroke="#666" />
            </BarChart>
        </ResponsiveContainer>
    );
};

AgesPyramidChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            adherent: PropTypes.number,
            conjoint: PropTypes.number,
            enfantLabel: PropTypes.number,
        }),
    ),
};
