import styled from '@emotion/styled';

export const Button = styled('button')(({ theme, primary }) => ({
    alignItems: 'center',
    backgroundColor: primary ? theme.colors.blueDark : theme.colors.white,
    border: `1px none`,
    borderRadius: '18px',
    boxShadow: primary
        ? `inset 1px 1px 1px ${theme.colors.blue}, 0px 2px 3px ${theme.colors
              .blueShadow}, 0px 2px 2px ${theme.colors.blueShadow2}`
        : `inset 1px 1px 1px ${theme.colors.grey}, 0px 2px 3px ${theme.colors
              .greyShadow}, 0px 2px 2px ${theme.colors.greyShadow2}`,
    boxSizing: 'border-box',
    color: primary ? theme.colors.white : theme.colors.black,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    outline: 'medium none currentcolor',
    overflow: 'hidden',
    padding: '9px 18px',
    position: 'relative',
    textDecoration: 'none',
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: primary ? theme.colors.blueDark : theme.colors.grey,
        color: primary ? theme.colors.white : theme.colors.blueDark,
    },

    '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        backgroundImage:
            'radial-gradient(circle, #000 10%, transparent 10.01%)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        transform: 'scale(10,10)',
        opacity: 0,
        transition: 'transform .5s, opacity 1s',
    },
    '&:active:after': {
        transform: 'scale(0,0)',
        opacity: 0.2,
        transition: '0s',
    },
}));
