const emptyData = {
    adherent: 0.001,
    conjoint: 0.001,
    enfant: 0.001,
};

export const getDistributionDataFromApiResponse = (apiData = {}) => [
    Object.keys(apiData).reduce(
        (acc, key) => (key !== 'total' ? { ...acc, [key]: apiData[key] } : acc),
        emptyData,
    ),
];
