import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { Tabs, Tab } from '../component/Tabs';
import { getExpenditureItemFromLocation } from './getExpenditureItemFromLocation';

const kaliviaItems = ['Optique'];
const geographyItems = ['Optique', 'Dentaire'];
const percentileItems = ['Optique', 'Dentaire'];

export const ExpendituresNavigation = ({ user }) => (
    <Route
        render={({ location }) => {
            const item = getExpenditureItemFromLocation(location);
            const showKalivia = kaliviaItems.includes(item);
            const showGeography = geographyItems.includes(item);
            const showPercentile = percentileItems.includes(item);

            return (
                <Tabs>
                    <Tab
                        data-test="navigate-expenditures-per-beneficiary"
                        to={{
                            pathname: '/expenditures',
                            search: location.search,
                        }}
                        exact
                    >
                        Evolution par bénéficiaire
                    </Tab>
                    <Tab
                        data-test="navigate-expenditures-per-item"
                        to={{
                            pathname: '/expenditures/perItem',
                            search: location.search,
                        }}
                        exact
                    >
                        Répartition par poste
                    </Tab>
                    {showKalivia && (
                        <Tab
                            data-test="navigate-expenditures-kalivia"
                            to={{
                                pathname: '/expenditures/kalivia',
                                search: location.search,
                            }}
                            exact
                        >
                            Focus Kalivia
                        </Tab>
                    )}
                    {showPercentile && user.role === 'commercial_hm' && (
                        <Tab
                            data-test="navigate-expenditures-percentile"
                            to={{
                                pathname: '/expenditures/percentile',
                                search: location.search,
                            }}
                            exact
                        >
                            Percentile
                        </Tab>
                    )}
                    {showGeography && user.role === 'commercial_hm' && (
                        <Tab
                            to={{
                                pathname: '/expenditures/geography',
                                search: location.search,
                            }}
                            exact
                        >
                            Répartition Geographique
                        </Tab>
                    )}
                </Tabs>
            );
        }}
    />
);

ExpendituresNavigation.propTypes = {
    user: PropTypes.object,
};
