import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { BreadcrumbItem } from './BreadcrumbItem';
import { formatAsCurrency, formatAsPercentage } from '../../service/format';

const List = styled('ul')({
    listStyle: 'none',
    margin: '10px 0',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    height: '40px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
});

const TotalItem = styled('li')({
    margin: '0.5rem 0 0.5rem 2.5rem',
});

const dictionary = {
    rc_repayment: 'régime complémentaire',
    ro_repayment: 'régime obligatoire',
    other_organization_repayment: 'remboursement autres organismes',
    leftover: 'reste à charge',
    expenditure: 'dépenses',
};

export class Breadcrumb extends Component {
    static propTypes = {
        focus: PropTypes.string.isRequired,
        items: PropTypes.array,
        onSelectNode: PropTypes.func.isRequired,
        quantity: PropTypes.number.isRequired,
        overItems: PropTypes.array,
        total: PropTypes.number.isRequired,
    };

    handleClick = node => event => {
        event.preventDefault();
        this.props.onSelectNode(node);
    };

    render() {
        const { items, overItems, total, focus, quantity } = this.props;

        if (!items.length) {
            return null;
        }

        const percentage = quantity / total;
        return (
            <div>
                <List>
                    {items.map(({ name, color }, index) => (
                        <BreadcrumbItem
                            key={name}
                            name={name}
                            color={color}
                            first={index === 0}
                            last={index === items.length - 1}
                            onClick={this.handleClick(name)}
                        />
                    ))}
                    <TotalItem>
                        {formatAsCurrency(total)} de {dictionary[focus]}
                    </TotalItem>
                </List>
                <List>
                    {overItems.map(({ name, color }, index) => (
                        <BreadcrumbItem
                            key={name}
                            name={name}
                            color={color}
                            first={index === 0}
                            last={index === overItems.length - 1}
                            onClick={this.handleClick(name)}
                        />
                    ))}
                    {overItems.length > 0 && (
                        <TotalItem>
                            {`${formatAsCurrency(
                                quantity,
                            )} (${formatAsPercentage(percentage)})`}
                        </TotalItem>
                    )}
                </List>
            </div>
        );
    }
}
