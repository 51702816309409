import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button } from '../component/Button';

const WorkingFilters = styled('div')(({ isLoading }) => ({
    cursor: isLoading ? 'wait' : 'default',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'rows',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
}));

const HalfButton = styled(Button)(({ isLoading, theme }) => ({
    backgroundColor: isLoading ? theme.colors.greyShadow : 'inherits',
    color: isLoading ? theme.colors.greyShadow2 : 'inherits',
    width: '47%',
    padding: '9px 0',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: 0,
}));
const FullButton = styled(Button)(({ isLoading, theme }) => ({
    backgroundColor: isLoading ? theme.colors.greyShadow : 'inherits',
    color: isLoading ? theme.colors.greyShadow2 : 'inherits',
    width: '100%',
    padding: '9px 0',
    justifyContent: 'center',
    textAlign: 'center',
}));

export const WorkingFilter = ({
    filterState,
    isLoading,
    onResetFilter,
    onAddSingleFilter,
}) => (
    <WorkingFilters id="working-filters" isLoading={isLoading}>
        <HalfButton
            onClick={() => onAddSingleFilter('working', 'all')}
            primary={filterState === 'all'}
            isLoading={isLoading}
            data-test="all"
        >
            Tous
        </HalfButton>
        <HalfButton
            onClick={() => onAddSingleFilter('working', 'retired')}
            primary={filterState === 'retired'}
            isLoading={isLoading}
            data-test="retired"
        >
            Retraités
        </HalfButton>
        <FullButton
            onClick={() => (filterState ? onResetFilter('working') : true)}
            primary={!filterState}
            isLoading={isLoading}
            data-test="active"
        >
            Actifs
        </FullButton>
    </WorkingFilters>
);

WorkingFilter.propTypes = {
    filterState: PropTypes.string,
    isLoading: PropTypes.bool,
    onResetFilter: PropTypes.func.isRequired,
    onAddSingleFilter: PropTypes.func.isRequired,
};
