import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'emotion-theming';
import { BrowserRouter as Router } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { getConfigurationPerHostname } from './configuration';

import * as d3 from 'd3';

import './index.css';
import { theme } from './theme';
import { App } from './application';

window.d3 = d3;
const hostname =
    typeof window !== 'undefined' ? window.location.hostname : 'localhost';
const configuration = getConfigurationPerHostname(hostname);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router>
            <App configuration={configuration} />
        </Router>
    </ThemeProvider>,
    document.getElementById('root'),
);
registerServiceWorker();
