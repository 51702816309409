import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Socket } from '../component/Socket';
import { Coverage } from './Coverage';
import { formatAsCurrency } from '../service/format';

const labels = {
    'Autres prestations Santé': 'Autres',
    'Autres prestations Hors Santé': 'Autres HS',
    'Honoraires médecins': 'Honoraire',
};

export const getLabel = name => labels[name] || name;

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    '&:hover .text': {
        color: theme.colors.blueDark,
    },
    '&:hover .coverage rect': {
        stroke: theme.colors.blueDark,
    },
    '&:hover .coverage .arrow': {
        stroke: theme.colors.blueDark,
    },
    '& .background-stroke': {
        transition: 'all 250ms ease-out',
    },
    '&:hover .background-stroke': {
        stroke: theme.colors.blueDark,
    },
}));

const Name = styled('h3')(({ theme }) => ({
    color: theme.colors.black,
    fontSize: '1rem',
    fontWeight: 600,
    margin: '0.5rem 0 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'all 250ms ease-out',
}));

const Expense = styled('p')(({ theme }) => ({
    color: theme.colors.black,
    fontWeight: 600,
    margin: '0.5rem 0 3rem',
    textAlign: 'center',
    transition: 'all 250ms ease-out',
}));

const IconContainer = styled('div')({
    alignSelf: 'center',
    display: 'flex',
    height: '6rem',
    position: 'relative',
    width: '6rem',

    '& > svg:first-child': {
        height: '6rem',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '6rem',
    },

    '& > svg:last-child': {
        height: '3rem',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        width: '3rem',
    },
});

export const RepaymentItem = ({ expense }) => {
    const { Icon } = require(`./${getLabel(expense.name)}`);

    return (
        <Container>
            <Coverage withArrow value={expense.coverage} />
            <IconContainer>
                <Socket className="background" />
                <Icon />
            </IconContainer>
            <Name className="text">{getLabel(expense.name)}</Name>
            <Expense className="text" data-test="repayment">
                {formatAsCurrency(expense.repayment)}
            </Expense>
        </Container>
    );
};

RepaymentItem.propTypes = {
    expense: PropTypes.shape({
        name: PropTypes.string.isRequired,
        repayment: PropTypes.number.isRequired,
    }).isRequired,
};
