import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';

import { SectionContainer } from '../component/Container';
import { FiltersProvider } from '../filters/FiltersProvider';
import { ExpendituresNavigation } from './ExpendituresNavigation';
import { ExpenditurePerBeneficiary } from './perBeneficiary/ExpenditurePerBeneficiary';
import { ExpenditurePerItem } from './perItem/ExpenditurePerItem';
import { KaliviaFocus } from './kaliviaFocus/KaliviaFocus';
import { NotFound } from '../NotFound';
import { Percentile } from './percentile/Percentile';
import { Geography } from './geography/Geography';
import { getExpenditureItemFromLocation } from './getExpenditureItemFromLocation';

const kaliviaItems = ['Optique'];
const geographyItems = ['Optique', 'Dentaire'];
const percentileItems = ['Optique', 'Dentaire'];

export const Expenditures = ({
    history,
    federation,
    location,
    match,
    user,
}) => (
    <FiltersProvider
        location={location}
        history={history}
        user={user}
        federation={federation}
        render={({ filters, onLoading }) => (
            <Fragment>
                <ExpendituresNavigation user={user} />
                <SectionContainer>
                    <Switch>
                        <Route
                            path={`${match.url}/perItem`}
                            render={({ location }) => (
                                <ExpenditurePerItem
                                    queryFilters={filters}
                                    history={history}
                                    location={location}
                                />
                            )}
                        />
                        <Route
                            path={`${match.url}/kalivia`}
                            render={({ location }) => {
                                const item = getExpenditureItemFromLocation(
                                    location,
                                );
                                if (!kaliviaItems.includes(item)) {
                                    return (
                                        <Redirect exact to="/expenditures" />
                                    );
                                }

                                return <KaliviaFocus queryFilters={filters} />;
                            }}
                        />
                        <Route
                            path={`${match.url}/geography`}
                            render={({ location }) => {
                                const item = getExpenditureItemFromLocation(
                                    location,
                                );
                                if (
                                    !geographyItems.includes(item) ||
                                    user.role !== 'commercial_hm'
                                ) {
                                    return (
                                        <Redirect exact to="/expenditures" />
                                    );
                                }

                                return (
                                    <Geography
                                        queryFilters={filters}
                                        location={location}
                                    />
                                );
                            }}
                        />
                        <Route
                            path={`${match.url}/percentile`}
                            render={({ location }) => {
                                const item = getExpenditureItemFromLocation(
                                    location,
                                );
                                const percentileItem = percentileItems.includes(
                                    item,
                                );

                                if (
                                    !percentileItem ||
                                    user.role !== 'commercial_hm'
                                ) {
                                    return (
                                        <Redirect exact to="/expenditures" />
                                    );
                                }

                                return (
                                    <Percentile
                                        location={location}
                                        queryFilters={filters}
                                    />
                                );
                            }}
                        />
                        <Route
                            path={match.url}
                            exact
                            render={({ location }) => (
                                <ExpenditurePerBeneficiary
                                    queryFilters={filters}
                                    location={location}
                                />
                            )}
                        />
                        <Route render={() => <NotFound />} />
                    </Switch>
                </SectionContainer>
            </Fragment>
        )}
    />
);

Expenditures.propTypes = {
    federation: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};
