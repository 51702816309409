import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container } from '../../component/Container';
import { AverageAge } from './AverageAge';
import { DistributionChart } from './DistributionChart';
import { getDistributionDataFromApiResponse } from './getDistributionDataFromApiResponse';
import { useDataProvider } from '../../service/DataProvider/hook';

const PageContainer = styled(Container)({
    justifyContent: 'space-around',

    '& > *': {
        position: 'relative',
    },
});

const AgesContainer = styled('div')({
    justifyContent: 'space-around',
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    padding: '3rem 0 5rem 0',
});

export const Distribution = ({ queryFilters }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/beneficiaries',
        {},
        queryFilters,
    );
    const [{ ages, beneficiaries }, setState] = useState({
        ages: [],
        beneficiaries: getDistributionDataFromApiResponse({}),
    });

    useEffect(() => {
        setState({
            ages: Object.keys(data.ages || {}).reduce(
                (acc, key) => [...acc, { key, value: data.ages[key] }],
                [],
            ),
            beneficiaries: getDistributionDataFromApiResponse(
                data.beneficiaries,
            ),
        });
    }, [data]);

    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);

    return (
        <Fragment>
            <PageContainer id="distribution-page">
                <DistributionChart data={beneficiaries} />
                <AgesContainer>
                    {ages.map(({ key, value }) => (
                        <AverageAge key={key} type={key} value={value} />
                    ))}
                </AgesContainer>
            </PageContainer>
        </Fragment>
    );
};

Distribution.propTypes = {
    queryFilters: PropTypes.object,
};
