import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { SocketedData, SocketedDataType } from '../../component/Socket';
import { formatAsPercentage } from '../../service/format';
import { theme } from '../../theme';

export const SocketedDataLabel = styled('div')(({ theme }) => ({
    color: theme.colors.black,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '300',
    marginBottom: '0.3rem',
}));

export const Attendance = ({ type, value }) => (
    <SocketedData
        color={theme.colors.orange}
        renderValue={() => (
            <Fragment>
                <div data-test="kalivia-attendance">
                    {formatAsPercentage(value)}
                </div>
            </Fragment>
        )}
        renderLabel={() => (
            <Fragment>
                <SocketedDataType>{type}</SocketedDataType>
            </Fragment>
        )}
    />
);

Attendance.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};
