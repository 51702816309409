import * as d3 from 'd3';

import getColorFromNode from './getColorFromNode';
import tooltipFactory from '../../component/tooltipFactory';
import { SunburstTooltip } from './SunburstTooltip';

const computeNode = (data, focus = 'rc_repayment') =>
    d3.hierarchy(data).sum(({ data }) => (data ? data[focus] : 0));

const getAncestors = d => {
    if (d.parent) {
        return [d.data.name, ...getAncestors(d.parent)];
    }

    return [d.data.name];
};

const isChildOf = (node, rootNode) => {
    if (rootNode.children.includes(node)) {
        return true;
    }

    if (node.parent) {
        return isChildOf(node.parent, rootNode);
    }

    return false;
};

export default class D3SunBurstChart {
    constructor({
        width,
        height,
        element,
        items,
        focus,
        onHover,
        onClick,
        onRender,
    }) {
        this.onClick = onClick;
        this.onHover = onHover;
        this.onRender = onRender;
        this.radius = Math.min(width, height) / 2 - 10;
        this.centerNode = computeNode(items, focus);

        this.onRender(this.centerNode);

        this.x = d3.scaleLinear().range([0, 2 * Math.PI]);

        this.y = d3.scaleSqrt().range([0, this.radius]);

        this.partition = d3.partition();

        this.arc = d3
            .arc()
            .startAngle(d => Math.max(0, Math.min(2 * Math.PI, this.x(d.x0))))
            .endAngle(d => Math.max(0, Math.min(2 * Math.PI, this.x(d.x1))))
            .innerRadius(d => Math.max(0, this.y(d.y0)))
            .outerRadius(d => Math.max(0, this.y(d.y1)));

        this.svg = d3
            .select(element)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2},${height / 2})`);

        this.nodes = this.partition(this.centerNode).descendants();

        const toolTip = tooltipFactory({
            ToolTip: SunburstTooltip,
        });
        this.svg.call(toolTip);
        this.toolTip = toolTip;
        this.svg.append('circle').attr('id', 'tipfollowscursor');

        this.createPaths();

        this.svg
            .selectAll('path')
            .transition()
            .duration(750)
            .attrTween('d', this.arcTweenData());
    }

    createPaths = () => {
        this.svg.selectAll('path').remove();
        const paths = this.svg.selectAll('path').data(this.nodes);
        const that = this;
        paths
            .enter()
            .append('path')
            .attr('d', this.arc)
            .style('cursor', d => (d.depth > 1 ? 'normal' : 'pointer'))
            .style('fill', d => (d.depth === 0 ? '#fff' : getColorFromNode(d)))
            .style('stroke', '#fff')
            .on('click', this.selectNode)
            .on('mouseover', function (d, i, nodes) {
                that.toolTip.show(d, i, this);
                that.mouseover(d, i, nodes, this);
            })
            .on('mouseleave', function (d, i, nodes) {
                that.toolTip.hide(d, i, nodes, this);
                that.mouseleave(d, i, nodes, this);
            });
    };

    arcTweenData = (oldNodes = []) => (a, i) => {
        // (a.x0s ? a.x0s : 0) -- grab the prev saved x0 or set to 0 (for 1st time through)
        // avoids the stash() and allows the sunburst to grow into being
        const old = oldNodes[i] || { x0: 0, x1: 0 };
        var oi = d3.interpolate(
            {
                x0: a.x0s ? a.x0s : old.x0,
                x1: a.x1s ? a.x1s : old.x1,
            },
            a,
        );
        const tween = t => {
            var b = oi(t);
            a.x0s = b.x0;
            a.x1s = b.x1;
            return this.arc(b);
        };
        if (i === 0) {
            // If we are on the first arc, adjust the x domain to match the root node
            // at the current zoom level. (We only need to do this once.)
            var xd = d3.interpolate(this.x.domain(), [
                this.centerNode.x0,
                this.centerNode.x1,
            ]);
            return t => {
                this.x.domain(xd(t));
                return tween(t);
            };
        } else {
            return tween;
        }
    };

    mouseover = node => {
        const centerNode = this.getCenterNode();
        const isChildOfCenterNode = isChildOf(node, centerNode);

        if (node.depth <= centerNode.depth) {
            return;
        }

        if (isChildOfCenterNode) {
            this.onHover(node);
        }
    };

    mouseleave = () => {
        this.onHover(null);
    };

    getCenterNode = () => this.centerNode;

    selectNode = d => {
        this.onClick(d.data.name);
    };

    zoom = centerName => {
        if (centerName === this.centerNode.data.name) {
            return;
        }
        this.centerNode = this.nodes.filter(d => d.data.name === centerName)[0];
        this.onRender(this.centerNode);
        this.svg
            .transition()
            .duration(750)
            .tween('scale', () => {
                var xd = d3.interpolate(this.x.domain(), [
                    this.centerNode.x0,
                    this.centerNode.x1,
                ]),
                    yd = d3.interpolate(this.y.domain(), [
                        this.centerNode.y0,
                        1,
                    ]),
                    yr = d3.interpolate(this.y.range(), [
                        this.centerNode.y0 ? 20 : 0,
                        this.radius,
                    ]);
                return t => {
                    this.x.domain(xd(t));
                    this.y.domain(yd(t)).range(yr(t));
                };
            })
            .selectAll('path')
            .attrTween('d', d => () => this.arc(d));
        this.svg.selectAll('image').remove();
    };

    draw = (items, focus) => {
        const prevCenterName = this.centerNode.data.name;
        const nextCenterNode = computeNode(items, focus);

        const oldNodes = this.nodes;
        this.nodes = this.partition(nextCenterNode).descendants();
        this.centerNode = this.nodes.filter(
            d => d.data.name === prevCenterName,
        )[0];

        this.onRender(this.centerNode);

        this.createPaths();

        this.svg
            .selectAll('path')
            .transition()
            .duration(750)
            .attrTween('d', this.arcTweenData(oldNodes));
        this.svg.selectAll('image').remove();
    };

    isLabelDisplayed = d => {
        if (this.centerNode.data.name === 'Santé') {
            return 1;
        }
        const ancestors = getAncestors(this.centerNode);

        return ancestors.indexOf(d.data.name) === -1 ? 'none' : 'block';
    };

    destroy = () => {
        this.svg.remove();
    };
}
