import React, { Fragment, useEffect, useReducer, useState } from 'react';

import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import styled from '@emotion/styled';

import { PrivateRoute } from '../PrivateRoute';
import { FederationRoute } from '../FederationRoute';
import { NavBar } from '../component/NavBar';
import { Home } from '../home/Home';
import { Beneficiaries } from '../beneficiaries/Beneficiaries';
import { Authentication } from '../authentication/Authentication';
import { AuthenticationNavBar } from '../authentication/AuthenticationNavBar';
import { DefaultNavBar } from '../DefaultNavBar';
import { Federations } from '../federations';
import { NotFound } from '../NotFound';
import { Logo } from '../component/Logo';
import { Expenditures } from '../expenditures/Expenditures';
import { WaitingForAuthentication } from '../WaitingForAuthentication';
import { LoadingBar } from '../component/LoadingBar';
import { fetch } from '../service/fetch';
import { getTitle } from './title';
import {
    getInitialState,
    reducer,
    USER_AUTHENTICATED,
    USER_CHOOSE_FEDERATION,
    USER_LOGOUT,
    USER_NOT_AUTHENTICATED,
    USER_RESET_FEDERATION,
} from './reducer';

const Main = styled('main')(({ theme }) => ({
    margin: '0 auto',
    maxWidth: `${theme.maxWidth}px`,
}));

export const UserContext = React.createContext({});

export const App = ({ configuration }) => {
    const [state, dispatch] = useReducer(
        reducer,
        getInitialState(configuration),
    );
    const [isLoading, setLoader] = useState(false);
    let loaderTimer = null;
    const displayLoader = state => {
        if (loaderTimer) {
            clearTimeout(loaderTimer);
            loaderTimer = null;
        }
        if (!!!state) {
            setLoader(false);
        } else {
            loaderTimer = setTimeout(() => {
                setLoader(true);
            }, 1000);
        }
    };

    const login = data => {
        const federationFromSession = window.sessionStorage.getItem(
            'federation',
        );
        dispatch({
            type: USER_AUTHENTICATED,
            payload: federationFromSession
                ? {
                      federation: JSON.parse(federationFromSession),
                      ...data,
                  }
                : data,
        });
    };

    const logout = () => {
        window.sessionStorage.clear();
        if (!configuration.loginRedirection) {
            return fetch({
                url: '/api/logout',
                apikey: configuration.apikey,
            }).then(() => {
                return dispatch({ type: USER_LOGOUT });
            });
        }
        return dispatch({ type: USER_LOGOUT });
    };

    const chooseFederation = federation => {
        window.sessionStorage.setItem(
            'federation',
            JSON.stringify({ label: federation.label, code: federation.code }),
        );
        dispatch({
            type: USER_CHOOSE_FEDERATION,
            payload: {
                federation,
            },
        });
    };

    const resetFederation = federation => {
        window.sessionStorage.removeItem('federation');
        dispatch({ type: USER_RESET_FEDERATION });
    };

    useEffect(() => {
        if (!state.userHasBeenChecked) {
            fetch({ url: '/api/user', apikey: configuration.apikey })
                .then(result => login(result))
                .catch(error => {
                    dispatch({
                        type: USER_NOT_AUTHENTICATED,
                    });
                });
        }

        return () => {
            if (loaderTimer) {
                clearTimeout(loaderTimer);
            }
        };
    }, [state.userHasBeenChecked]);

    const {
        apikey,
        federation,
        filters,
        lastImportDate,
        loginRedirection,
        user,
        userHasBeenChecked,
    } = state;

    if (!userHasBeenChecked) {
        return <WaitingForAuthentication />;
    }

    return (
        <Fragment>
            <UserContext.Provider
                value={{
                    apikey,
                    displayLoader,
                    federation,
                    filters,
                    logout,
                    user,
                }}
            >
                <Route
                    path="/"
                    render={routeProps => {
                        const isLoginPage =
                            routeProps.location.pathname === '/login';

                        return (
                            <NavBar inverted={isLoginPage}>
                                <Switch>
                                    <Route
                                        path="/login"
                                        render={() => <AuthenticationNavBar />}
                                    />
                                    <Route
                                        path="/"
                                        render={() =>
                                            user ? (
                                                <DefaultNavBar
                                                    user={user}
                                                    federation={federation}
                                                    onLogout={
                                                        !loginRedirection
                                                            ? logout
                                                            : null
                                                    }
                                                    onRemoveFederation={
                                                        filters.length > 1
                                                            ? resetFederation
                                                            : null
                                                    }
                                                    title={getTitle(
                                                        routeProps.location,
                                                        federation,
                                                        lastImportDate,
                                                    )}
                                                    pathName={
                                                        routeProps.location
                                                            .pathname
                                                    }
                                                />
                                            ) : null
                                        }
                                    />
                                </Switch>
                            </NavBar>
                        );
                    }}
                />
                <Main>
                    <LoadingBar isLoading={isLoading} />
                    <Switch>
                        <FederationRoute
                            exact
                            path="/"
                            user={user}
                            federation={federation}
                            render={() => <Home />}
                        />
                        <FederationRoute
                            path="/beneficiaries"
                            user={user}
                            federation={federation}
                            render={({ history, location, match }) => (
                                <Beneficiaries
                                    federation={federation}
                                    match={match}
                                    location={location}
                                    history={history}
                                    user={user}
                                />
                            )}
                        />
                        <FederationRoute
                            path="/expenditures"
                            user={user}
                            federation={federation}
                            render={({ history, location, match }) => (
                                <Expenditures
                                    federation={federation}
                                    match={match}
                                    location={location}
                                    history={history}
                                    user={user}
                                />
                            )}
                        />
                        <PrivateRoute
                            path="/federations"
                            user={user}
                            render={({ history, location }) => (
                                <Federations
                                    onSetFederation={chooseFederation}
                                    filters={filters}
                                    location={location}
                                />
                            )}
                        />
                        <Route
                            path="/login"
                            render={({ location }) => {
                                if (loginRedirection) {
                                    window.location = loginRedirection;
                                    return null;
                                }
                                return (
                                    <Authentication
                                        location={location}
                                        onLogin={login}
                                    />
                                );
                            }}
                        />
                        <Route render={() => <NotFound />} />
                    </Switch>
                </Main>
                <Logo />
            </UserContext.Provider>
        </Fragment>
    );
};

App.propTypes = {
    configuration: PropTypes.object.isRequired,
};
