import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LegendContainer = styled('ul')(({ width }) => ({
    display: 'flex',
    listStyle: 'none',
    marginLeft: '0.5rem',
    width,
}));

const LegendItem = styled('li')({
    flexBasis: '50%',
    fontWeight: 'bold',
    padding: '0 5rem',

    '&:first-child': {
        textAlign: 'right',
    },
});

const LegendLabels = {
    male: 'Hommes',
    female: 'Femmes',
};

export const LegendContent = ({ payload, width }) => {
    return (
        <LegendContainer width={width}>
            {payload.map((entry, index) => (
                <LegendItem key={`item-${index}`}>
                    {LegendLabels[entry.value]}
                </LegendItem>
            ))}
        </LegendContainer>
    );
};

LegendContent.propTypes = {
    payload: PropTypes.array,
    width: PropTypes.number,
};
