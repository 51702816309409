import { useContext, useEffect, useReducer } from 'react';

import { fetch } from '../fetch';
import { UserContext } from '../../application';
import { writeToCache, readFromCache } from '../cache';
import {
    reducer,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    UPDATE_FETCH_URL,
} from './reducer';
import { generateApiCall } from './tools';

export const useDataProvider = (url, emptyData, queryFilters) => {
    const { apikey, logout, displayLoader, federation } = useContext(
        UserContext,
    );

    const [{ data, isLoading, isError, apiCall }, dispatch] = useReducer(
        reducer,
        {
            data: emptyData,
            isLoading: false,
            isError: false,
            apiCall: generateApiCall(url, federation, queryFilters),
        },
    );

    const fetchData = () => {
        const data = readFromCache(apiCall);
        if (data) {
            return dispatch({
                type: FETCH_SUCCESS,
                payload: { data },
            });
        }
        dispatch({ type: FETCH_START });
        displayLoader(true);
        return fetch({ url: apiCall, apikey })
            .then(result => {
                writeToCache(apiCall, result);
                dispatch({
                    type: FETCH_SUCCESS,
                    payload: { data: result },
                });
            })
            .catch(error => {
                if (error.code >= 401 && error.code <= 403) {
                    return logout();
                }
                dispatch({
                    type: FETCH_ERROR,
                    payload: { error },
                });
            })
            .finally(() => displayLoader(false));
    };

    const updateQueryFilters = newQueryFilters => {
        dispatch({
            type: UPDATE_FETCH_URL,
            payload: { url, federation, queryFilters: newQueryFilters },
        });
    };

    useEffect(() => {
        fetchData();
    }, [apiCall]);

    return [data, updateQueryFilters, isLoading, isError];
};
