import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Legends, Legend, TitleLegend } from '../component/Charts';
import { theme } from '../theme';

export const OPTICS = 'Optique';
const OPTICS_FOCUS_SIMPLE = 'simple';
const OPTICS_FOCUS_COMPLEX = 'complex';
const OPTICS_FOCUS_VERY_COMPLEX = 'veryComplex';
const OPTICS_FOCUS_FRAMES = 'frame';

const OPTICS_FILTER_ALL = 'all';
const OPTICS_FILTER_ADULTS = 'adults';
const OPTICS_FILTER_CHILDREN = 'children';

export const DENTAL = 'Dentaire';
const DENTAL_FOCUS_PROTHESIS = 'prothesis';
const DENTAL_FOCUS_ORTHODONTICS = 'orthodontics';

export const FILTERS = {
    [DENTAL]: {
        focus: {
            default: DENTAL_FOCUS_PROTHESIS,
            items: [
                {
                    value: DENTAL_FOCUS_PROTHESIS,
                    label: 'Prothèses',
                },
                {
                    value: DENTAL_FOCUS_ORTHODONTICS,
                    label: 'Orthodontie',
                },
            ],
        },
    },
    [OPTICS]: {
        focus: {
            default: OPTICS_FOCUS_SIMPLE,
            items: [
                {
                    value: OPTICS_FOCUS_SIMPLE,
                    label: 'Verres simples',
                },
                {
                    value: OPTICS_FOCUS_COMPLEX,
                    label: 'Verres complexes',
                },
                {
                    value: OPTICS_FOCUS_VERY_COMPLEX,
                    label: 'Verres très complexes',
                },
                {
                    value: OPTICS_FOCUS_FRAMES,
                    label: 'Montures',
                },
            ],
        },
        filters: {
            default: OPTICS_FILTER_ALL,
            items: [
                {
                    value: OPTICS_FILTER_ALL,
                    label: 'Tous',
                },
                {
                    value: OPTICS_FILTER_ADULTS,
                    label: 'Adultes',
                },
                {
                    value: OPTICS_FILTER_CHILDREN,
                    label: 'Enfants',
                },
            ],
        },
    },
};

const FocusContainer = styled('div')({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1rem',

    '& > button': {
        margin: '0 1rem 0 1rem',
    },
});

export class FocusSelector extends Component {
    static propTypes = {
        focuses: PropTypes.array.isRequired,
        filters: PropTypes.array.isRequired,
        onSetFocus: PropTypes.func.isRequired,
        onSetFilter: PropTypes.func.isRequired,
        selectedFocus: PropTypes.string.isRequired,
        selectedFilter: PropTypes.string.isRequired,
    };

    handleFocusClick = selectedItem => {
        const { onSetFocus } = this.props;
        onSetFocus(selectedItem);
    };

    handleFilterClick = selectedItem => {
        const { onSetFilter } = this.props;
        onSetFilter(selectedItem);
    };

    render() {
        const { focuses, filters, selectedFocus, selectedFilter } = this.props;

        return (
            <Fragment>
                <FocusContainer>
                    <Legends>
                        {focuses.map(({ value, label }) => (
                            <Legend key={value}>
                                <TitleLegend
                                    className={
                                        value === selectedFocus ? (
                                            'selected'
                                        ) : (
                                            'unselected'
                                        )
                                    }
                                    onClick={() => this.handleFocusClick(value)}
                                >
                                    {label}
                                </TitleLegend>
                            </Legend>
                        ))}
                    </Legends>
                </FocusContainer>
                {filters && (
                    <FocusContainer>
                        {filters.map(({ value, label }) => (
                            <Legend key={value}>
                                <TitleLegend
                                    accentColor={theme.colors.blueDark}
                                    className={
                                        value === selectedFilter ? (
                                            'selected'
                                        ) : (
                                            'unselected'
                                        )
                                    }
                                    onClick={() =>
                                        this.handleFilterClick(value)}
                                >
                                    {label}
                                </TitleLegend>
                            </Legend>
                        ))}
                    </FocusContainer>
                )}
            </Fragment>
        );
    }
}
