import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Container } from './component/Container';

const WaitingForAuthenticationContainer = styled(Container)(({ theme }) => ({
    color: theme.colors.black,
    flexDirection: 'column',
    textAlign: 'center',
}));

export class WaitingForAuthentication extends Component {
    state = { display: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ display: true });
        }, 300);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        if (!this.state.display) {
            return null;
        }
        return (
            <WaitingForAuthenticationContainer>
                <p>Connexion en cours ...</p>
            </WaitingForAuthenticationContainer>
        );
    }
}
