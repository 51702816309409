import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { parse, stringify } from 'query-string';
import isEqual from 'lodash/isEqual';

import { Container } from '../component/Container';
import { Filters } from './Filters';
import { getExpenditureItemFromLocation } from '../expenditures/getExpenditureItemFromLocation';

const LayoutWithFilters = styled(Container)({
    flexDirection: 'row',
    justifyContent: 'start',
});

const PageContainer = styled(Container)({
    flexDirection: 'column',
    width: '100%',
});

const sanitizeQueryFilters = filters => ({
    working: ['all', 'retired'].includes(filters.working)
        ? filters.working
        : null,
    companies: filters.companies || null,
});

const defaultFilters = {
    working: null,
    companies: null,
};

export const generateQueryFiltersString = (filters = {}) => {
    // do not send empty value as query params
    const notEmptyFilters = Object.keys(filters)
        .filter(key => filters[key])
        .reduce((acc, key) => {
            acc[key] = filters[key];
            return acc;
        }, {});
    return Object.keys(notEmptyFilters).length
        ? `?${stringify(notEmptyFilters, { arrayFormat: 'bracket' })}`
        : null;
};

export const addCompanyAsFilter = (companyCode, filters, currentFilters) => [
    ...filters.map(filter => `${companyCode}*${filter}`),
    ...currentFilters.filter(filter => !filter.includes(companyCode)),
];

export const removeCompanyAsFilter = (
    companyCode,
    currentCompaniesFilter = [],
) => [
    ...currentCompaniesFilter.filter(filter => !filter.includes(companyCode)),
];

export class FiltersProvider extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        render: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        federation: PropTypes.object.isRequired,
    };

    state = { ...defaultFilters, isLoading: false };

    componentWillMount() {
        this.setState(
            sanitizeQueryFilters(
                parse(this.props.location.search, {
                    arrayFormat: 'bracket',
                }),
            ),
        );
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.location.search, this.props.location.search)) {
            this.setState(
                sanitizeQueryFilters(
                    parse(nextProps.location.search, {
                        arrayFormat: 'bracket',
                    }),
                ),
            );
        }
    }

    generateQueryFilters = filters => {
        const globalFilters = {
            ...this.state,
            item: getExpenditureItemFromLocation(this.props.location),
            ...filters,
        };

        return generateQueryFiltersString(globalFilters);
    };

    handleAddSingleFilter = (filterName, value) => {
        if (value === this.state[filterName] || this.state.isLoading) {
            return true;
        }
        const { location, history } = this.props;
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: value,
            }),
        });
    };

    handleAddFilter = (filterName, value) => {
        if (this.state.isLoading) {
            return;
        }

        if (
            !this.state[filterName] ||
            !this.state[filterName].includes(value)
        ) {
            const { location, history } = this.props;
            history.push({
                pathname: location.pathname,
                search: this.generateQueryFilters({
                    [filterName]: [...(this.state[filterName] || []), value],
                }),
            });
        }
    };

    handleRemoveFilter = (filterName, value) => {
        if (this.state.isLoading) {
            return;
        }

        if (!this.state[filterName]) {
            return;
        }
        const filters = this.state[filterName].filter(
            filter => filter !== value,
        );
        const { location, history } = this.props;
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: filters.length ? filters : null,
            }),
        });
    };

    handleSelectFirstFilter = (filterName, filters) => {
        if (this.state.isLoading) {
            return;
        }

        const { location, history } = this.props;
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: filters,
            }),
        });
    };

    handleResetFilter = filterName => {
        if (this.state.isLoading) {
            return;
        }

        const { location, history } = this.props;
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: defaultFilters[filterName],
            }),
        });
    };

    handleUnSelectCompany = (filterName, companyCode, filters = []) => {
        if (this.state.isLoading) {
            return;
        }
        const { location, history } = this.props;
        const currentCompaniesFilter = this.state.companies || [];
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: addCompanyAsFilter(
                    companyCode,
                    filters,
                    currentCompaniesFilter,
                ),
            }),
        });
    };

    handleResetCompany = (filterName, companyCode) => {
        if (this.state.isLoading) {
            return;
        }

        const { location, history } = this.props;
        const currentCompaniesFilter = this.state.companies || [];
        history.push({
            pathname: location.pathname,
            search: this.generateQueryFilters({
                [filterName]: removeCompanyAsFilter(
                    companyCode,
                    currentCompaniesFilter,
                ),
            }),
        });
    };

    handleLoading = isLoading => {
        this.setState({ isLoading });
    };

    render() {
        const { isLoading, ...filters } = this.state;

        return (
            <LayoutWithFilters>
                <Filters
                    federation={this.props.federation}
                    filters={this.state}
                    location={this.props.location}
                    onAddFilter={this.handleAddFilter}
                    onAddSingleFilter={this.handleAddSingleFilter}
                    onRemoveFilter={this.handleRemoveFilter}
                    onResetFilter={this.handleResetFilter}
                    onSelectFirstFilter={this.handleSelectFirstFilter}
                    onUnselectCompany={this.handleUnSelectCompany}
                    onResetCompany={this.handleResetCompany}
                    user={this.props.user}
                    isLoading={isLoading}
                />
                <PageContainer>
                    {this.props.render({
                        filters,
                        onLoading: this.handleLoading,
                        ...this.props,
                    })}
                </PageContainer>
            </LayoutWithFilters>
        );
    }
}
