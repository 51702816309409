import React from 'react';
import styled from '@emotion/styled';
import logo from './logo.png';

const LogoContainer = styled('div')({
    position: 'fixed',
    bottom: 10,
    right: 10,
    width: 150,
});

const LogoImage = styled('img')({
    height: 'auto',
    width: '100%',
});

export const Logo = () => (
    <LogoContainer>
        <LogoImage src={logo} />
    </LogoContainer>
);
