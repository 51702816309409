import numeral from 'numeral';
import 'numeral/locales/fr';

numeral.locale('fr');

const supportNativeFormatting = !!(
    typeof Intl === 'object' &&
    Intl &&
    typeof Intl.NumberFormat === 'function'
);

export const formatAsCurrency = (number, withDecimals = true) =>
    number
        ? supportNativeFormatting
          ? number.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                ...(withDecimals
                    ? {}
                    : { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            })
          : // $ is the character to use to tell numeral to insert the currency symbol
            numeral(number).format(withDecimals ? '0,0.00 $' : '0,0 $')
        : '0,00 €';

export const formatAsPercentage = (number, options = {}) => {
    if (!number) {
        return 0;
    }
    const result = supportNativeFormatting
        ? number.toLocaleString('fr-FR', {
              style: 'percent',
          })
        : numeral(number).format('0%');

    if (options.showSign && number > 0) {
        return `+${result}`;
    }

    return result;
};
