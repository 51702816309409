import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const Socket = ({
    className,
    color = '#F29400',
    width = 4,
    title = null,
}) => (
    <svg
        className={className}
        width="112px"
        height="123px"
        viewBox="0 0 112 123"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        {title && <title>{title}</title>}
        <defs />
        <g
            id="ACCUEIL"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            transform="translate(-1264.000000, -621.000000)"
        >
            <g
                className="background-stroke"
                transform="translate(1258.000000, 620.000000)"
                stroke={color}
                strokeWidth={width}
            >
                <path
                    d="M72.1958099,5.923113 L105.931659,25.4190392 C112.115662,28.9927707 115.924528,35.5930586 115.924528,42.7354285 L115.924528,81.7602237 C115.924528,88.9025936 112.115662,95.5028815 105.931659,99.0766129 L72.1958099,118.572539 C66.0041651,122.150687 58.3731934,122.150687 52.1815486,118.572539 L18.4456996,99.0766129 C12.2616967,95.5028815 8.45283019,88.9025936 8.45283019,81.7602237 L8.45283019,42.7354285 C8.45283019,35.5930586 12.2616967,28.9927707 18.4456996,25.4190392 L52.1815486,5.923113 C58.3731934,2.34496535 66.0041651,2.34496535 72.1958099,5.923113 Z"
                    id="Polygon"
                />
            </g>
        </g>
    </svg>
);

Socket.propTypes = {
    className: PropTypes.string,
};

const Container = styled('div')(({ color }) => ({
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    color,

    '& .background-stroke': {
        stroke: color,
    },
}));

const SocketContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    height: '5rem',
    marginRight: '1rem',
    position: 'relative',
    width: '5rem',
    maxWidth: '5rem',
});

const SocketBackground = styled(Socket)({
    height: '5rem',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '5rem',
});

const SocketValue = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
});

const LabelContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
});

export const SocketedDataLabel = styled('div')(({ theme }) => ({
    color: theme.colors.greyShadow2,
    display: 'flex',
    flexDirection: 'column',
}));

export const SocketedDataType = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
});

export const SocketedData = ({ color, renderLabel, renderValue, ...rest }) => (
    <Container color={color} {...rest}>
        <SocketContainer>
            <SocketBackground />
            <SocketValue>{renderValue()}</SocketValue>
        </SocketContainer>
        <LabelContainer>{renderLabel()}</LabelContainer>
    </Container>
);

SocketedData.propTypes = {
    color: PropTypes.string,
    renderLabel: PropTypes.func.isRequired,
    renderValue: PropTypes.func.isRequired,
};
