import React from 'react';
import PropTypes from 'prop-types';
import { HiddenTable } from '../../component/HiddenTable';
import { formatAsCurrency } from '../../service/format';

const CaptionsDictionary = {
    all: 'Tous',
    adults: 'Adultes',
    children: 'Enfants',
};

const GeographyDataTableBody = ({ keys, names, data }) => (
    <HiddenTable>
        <caption>
            {names.map(name => CaptionsDictionary[name] || name).join(' - ')}
        </caption>
        <thead>
            <tr>
                <th>Departement</th>
                <th>Nombre d{"'"}actes</th>
                <th>Dépense</th>
                <th>Remboursement obligatoire</th>
                <th>Remboursement complémentaire</th>
                <th>Autres remboursements</th>
                <th>Reste à charge</th>
            </tr>
        </thead>
        <tbody>
            {Object.keys(data).map(
                key =>
                    key === 'name' ? null : (
                        <tr data-test={`${keys.join('-')}`} key={key}>
                            <td>{data[key].department}</td>
                            <td>{data[key].act_quantity}</td>
                            <td>{formatAsCurrency(data[key].expenditure)}</td>
                            <td>{formatAsCurrency(data[key].ro_repayment)}</td>
                            <td>{formatAsCurrency(data[key].rc_repayment)}</td>
                            <td>
                                {formatAsCurrency(
                                    data[key].other_organization_repayment,
                                )}
                            </td>
                            <td>{formatAsCurrency(data[key].leftover)}</td>
                        </tr>
                    ),
            )}
        </tbody>
    </HiddenTable>
);

GeographyDataTableBody.propTypes = {
    data: PropTypes.object,
    keys: PropTypes.array,
    names: PropTypes.array,
};

/**
 * This component recursivly traverse the data which may have multiple levels of filters.
 * In optics we have:
 * - all
 * - children
 * - adults
 * And for each of them, the differents focuses (Simple, Complex, Very complex and Frames)
 * 
 * However, in dentals we only have one level of filters: Orthodontics and prothesis
 */
export const GeographyDataTable = ({
    data,
    filterLevel = 1,
    filterLevels,
    keys = [],
    names = [],
}) =>
    Object.keys(data).map(
        key =>
            key === 'name' ? null : filterLevel < filterLevels ? (
                <GeographyDataTable
                    key={[...keys, key].join('-')}
                    data={data[key]}
                    filterLevel={filterLevel + 1}
                    filterLevels={filterLevels}
                    names={[...names, data[key].name]}
                    keys={[...keys, key]}
                />
            ) : (
                <GeographyDataTableBody
                    key={[...keys, key].join('-')}
                    data={data[key]}
                    names={[...names, data[key].name]}
                    keys={[...keys, key]}
                />
            ),
    );

GeographyDataTable.propTypes = {
    data: PropTypes.object,
    filterLevel: PropTypes.number,
    filterLevels: PropTypes.number,
};
