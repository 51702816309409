import * as d3 from 'd3';

import { categories } from './ExpenditurePerItem';

const hue = d3.scaleOrdinal(d3.schemeCategory10);

const luminance = d3
    .scaleSqrt()
    .domain([0, 1e3])
    .clamp(true)
    .range([0.9, 0.1]);

export default node => {
    let parent = node;
    while (parent.depth > 1) parent = parent.parent;
    const category = categories.find(cat => cat.name === parent.data.name);
    const color = category ? d3.hsl(category.color) : d3.hsl(hue(parent.data.name));
    if (isNaN(color.h)) {
        color.h = 360;
    }
    color.l = luminance(node.value / parent.value * 100);

    return color;
};
