import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { MonthlyChart } from './MonthlyChart';
import {
    ChipLegend,
    Container,
    Legend,
    Legends,
    Title,
    TitleLegend,
} from '../../component/Charts';
import { useDataProvider } from '../../service/DataProvider/hook';

const AdherentChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.orange,
}));
const ConjointChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.blueDark,
}));
const EnfantChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.blue,
}));

const getDataWithFilter = (data, filter) =>
    data.map(d => ({
        ...d,
        filter,
    }));

export const Monthly = ({ queryFilters }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/beneficiaries-months',
        [],
        queryFilters,
    );
    const [filterState, setFilterState] = useState({
        filter: null,
        filteredData: getDataWithFilter(data, null),
    });

    const applyFilter = (newFilter = null) => {
        setFilterState({
            filter: newFilter,
            filteredData: getDataWithFilter(data, newFilter),
        });
    };

    useEffect(() => {
        setFilterState({
            filter: filterState.filter,
            filteredData: getDataWithFilter(data, filterState.filter),
        });
    }, [data]);

    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);

    const currentYear =
        filterState.filteredData &&
        filterState.filteredData[0] &&
        filterState.filteredData[0].year
            ? filterState.filteredData[0].year
            : null;

    return (
        <Container id="monthly-page">
            <Title>
                Effectifs mensuels
                {currentYear && ` pour l'année ${currentYear}`}
            </Title>
            <MonthlyChart
                data={filterState.filteredData}
                filter={filterState.filter}
            />
            <Legends>
                <Legend>
                    <TitleLegend
                        className={
                            !filterState.filter ? 'selected' : 'unselected'
                        }
                        onClick={() => applyFilter()}
                    >
                        Tous
                    </TitleLegend>
                </Legend>
                <Legend>
                    <AdherentChip />
                    <TitleLegend
                        className={
                            filterState.filter === 'adherent'
                                ? 'selected'
                                : 'unselected'
                        }
                        onClick={() => applyFilter('adherent')}
                    >
                        Adhérents
                    </TitleLegend>
                </Legend>
                <Legend>
                    <ConjointChip />
                    <TitleLegend
                        className={
                            filterState.filter === 'conjoint'
                                ? 'selected'
                                : 'unselected'
                        }
                        onClick={() => applyFilter('conjoint')}
                    >
                        Conjoints
                    </TitleLegend>
                </Legend>
                <Legend>
                    <EnfantChip />
                    <TitleLegend
                        className={
                            filterState.filter === 'enfant'
                                ? 'selected'
                                : 'unselected'
                        }
                        onClick={() => applyFilter('enfant')}
                    >
                        Enfants
                    </TitleLegend>
                </Legend>
            </Legends>
        </Container>
    );
};
