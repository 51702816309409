export const theme = {
    colors: {
        white: 'rgb(255, 255, 255)',
        grey: 'rgb(225, 225, 225)',
        greyShadow: 'rgba(0, 0, 0, 0.12)',
        greyShadow2: 'rgba(0, 0, 0, 0.33)',
        black: 'rgb(88, 88, 90)',
        orange: 'rgb(250, 187, 0)',
        yellow: 'rgb(255, 221, 0)',
        blue: 'rgb(126, 170, 191)',
        blueShadow: 'rgba(126, 170, 191, 0.12)',
        blueShadow2: 'rgba(126, 170, 191, 0.33)',
        blueDark: 'rgb(0, 100, 159)',
        red: '#EF5350',
        green: '#669B00',
        purple: '#9B0066',
    },
    spacing: {
        navBarChildren: {
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
        },
    },
    maxWidth: 1280,
    transitionDuration: '500ms',
};
