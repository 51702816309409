export const getConfigurationPerHostname = hostname => {
    switch (hostname) {
        case 'dataviz.harmonie-mutuelle.fr':
            return {
                apiHost: 'https://api.harmonie-mutuelle.fr/services/dataviz-entreprise-api',
                apikey: '3f57307332db4c2bb935d1798f8aea90',
                loginUrl:
                    'https://harmonie-mutuelle.fr',
            };
        case 'localhost':
            return {
                apiHost:
                    process.env.REACT_APP_API_URL ||
                    'http://localhost:8080/dataviz-entreprise-api',
                apikey: 'development',
            };
        default:
            return {
                apiHost: 'https://rec.api.harmonie-mutuelle.fr/services/dataviz-entreprise-api',
                apikey: '3f57307332db4c2bb935d1798f8aea90',
                loginUrl:
                    'https://rec.harmonie-mutuelle.fr',
            };
    }
};
