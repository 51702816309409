export const getAgePyramidDataFromApiResponse = (apiData = []) => {
    const males = apiData.find(d => d.sex === 'M') || {};
    const females = apiData.find(d => d.sex === 'F') || {};

    return [
        {
            name: '> 60 ans',
            male: -parseInt(males.above_60 || 0, 10),
            female: parseInt(females.above_60 || 0, 10),
        },
        {
            name: '< 60 ans',
            male: -parseInt(males.below_60 || 0, 10),
            female: parseInt(females.below_60 || 0, 10),
        },
        {
            name: '< 50 ans',
            male: -parseInt(males.below_50 || 0, 10),
            female: parseInt(females.below_50 || 0, 10),
        },
        {
            name: '< 40 ans',
            male: -parseInt(males.below_40 || 0, 10),
            female: parseInt(females.below_40 || 0, 10),
        },
        {
            name: '< 30 ans',
            male: -parseInt(males.below_30 || 0, 10),
            female: parseInt(females.below_30 || 0, 10),
        },
        {
            name: '< 20 ans',
            male: -parseInt(males.below_20 || 0, 10),
            female: parseInt(females.below_20 || 0, 10),
        },
    ];
};
