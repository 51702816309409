import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Redirect } from 'react-router';

import { TextField } from '../component/TextField';
import { Button } from '../component/Button';
import { Alert } from '../component/Alert';
import { Title } from '../component/Title';
import { fetch } from '../service/fetch';

const LoginForm = styled('form')({
    margin: '0 auto',
    maxWidth: '400px',
    padding: '3rem 0 0 0',
});

const LoginTitle = styled(Title)({
    fontSize: '1.75rem',
    marginBottom: '3rem',
});

const LoginButtons = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3rem',
});

export class Authentication extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        onLogin: PropTypes.func.isRequired,
    };

    state = {
        login: '',
        password: '',
        redirectToReferrer: false,
    };

    handleSubmit = event => {
        event.preventDefault();
        const { login, password } = this.state;

        fetch({
            url: '/api/login',
            body: JSON.stringify({ login, password }),
            method: 'POST',
        })
            .then(response => {
                this.props.onLogin(response);
                this.setState({ error: null, redirectToReferrer: true });
            })
            .catch(error => {
                this.setState({ error });
            });
    };

    handleChange = name => event => {
        event.preventDefault();
        this.setState({ [name]: event.target.value });
    };

    render() {
        const { error, login, password, redirectToReferrer } = this.state;
        const { from } = this.props.location.state || {
            from: { pathname: '/' },
        };

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }

        return (
            <LoginForm id="login_form" onSubmit={this.handleSubmit}>
                <LoginTitle>Connectez-vous !</LoginTitle>
                {error && (
                    <Alert>
                        <p>
                            {error.code === 401
                                ? `Email ou mot de passe invalide`
                                : error.message}
                        </p>
                    </Alert>
                )}

                <TextField
                    name="login"
                    label="Email"
                    value={login}
                    onChange={this.handleChange('login')}
                />

                <TextField
                    type="password"
                    name="password"
                    label="Mot de passe"
                    value={password}
                    onChange={this.handleChange('password')}
                />

                <LoginButtons>
                    <Button type="submit">Se connecter</Button>
                </LoginButtons>
            </LoginForm>
        );
    }
}
