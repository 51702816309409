import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '1rem',

    '& > input:placeholder-shown:not(:focus) + label': {
        fontSize: '100%',
        opacity: '.8',
        top: '2rem',
    },
});

const Label = styled('label')(({ theme }) => ({
    color: theme.colors.black,
    position: 'absolute',
    left: 0,
    top: '0.5rem',
    cursor: 'text',
    fontSize: '75%',
    opacity: 1,
    transition: 'all .2s',
}));

const Input = styled('input')(({ theme }) => ({
    background: 'transparent no-repeat',
    backgroundImage: `linear-gradient(to bottom, ${theme.colors.blue}, ${theme.colors.blue}), linear-gradient(to bottom, ${theme.colors.black}, ${theme.colors.black})`,
    /* sizes for the 2 images (default state) */
    backgroundSize: '0 2px, 100% 1px',
    /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
    backgroundPosition: '50% 100%, 50% 100%',
    border: 0,
    color: theme.colors.black,
    marginTop: '1rem',
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    transition: 'background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1)',

    '&:focus': {
        backgroundSize: '100% 2px, 100% 1px',
        outline: 'none',
    },
}));

export const TextField = ({ label, name, id, placeholder = '', ...props }) => {
    const finalId = id || name;

    return (
        <Container>
            <Input
                id={finalId}
                name={name}
                placeholder={placeholder}
                {...props}
            />
            <Label htmlFor={finalId}>{label}</Label>
        </Container>
    );
};

TextField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
};
