import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import kaliviaLogo from './kalivia.png';

const KaliviaLabelContainer = styled('ul')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    listStyleType: 'none',

    '& li': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },

    '& li + li': {
        marginLeft: '3rem',
    },
});

const KaliviaLabelColor = styled('div')(({ color }) => ({
    backgroundColor: color,
    height: '1rem',
    width: '1rem',
    marginRight: '0.5rem',
}));

const KaliviaLogo = styled('div')({
    background: `url(${kaliviaLogo})`,
    backgroundSize: 'cover',
    opacity: 0.75,
    width: 100,
    height: 50,
});

export const KaliviaLegend = ({ payload }) => (
    <KaliviaLabelContainer>
        {payload.map(({ color, value }) => (
            <li key={`item-${value}`}>
                <KaliviaLabelColor color={color} />
                {value === 'Kalivia' ? <KaliviaLogo /> : value}
            </li>
        ))}
    </KaliviaLabelContainer>
);

KaliviaLegend.propTypes = {
    payload: PropTypes.object,
};
