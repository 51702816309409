import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BarLoader } from 'react-spinners';
import { theme } from '../theme';

const Container = styled('div')({
    height: '0.5rem',
    position: 'relative',
    padding: '0.25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
        width: '100%',
    },
});

export const LoadingBar = ({ isLoading = false }) => (
    <Container>
        <BarLoader color={theme.colors.blue} loading={isLoading} />
    </Container>
);

LoadingBar.propTypes = {
    isLoading: PropTypes.bool,
};
