import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {
    Bar,
    BarChart,
    Legend as ChartLegend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { theme } from '../../theme';
import { getKaliviaDataFromApiResponse } from './getKaliviaDataFromApiResponse';
import { Container } from '../../component/Container';
import { Attendance } from './Attendance';
import { KaliviaLegend } from './KaliviaLegend';
import { KaliviaDataTable } from './KaliviaDataTable';
import { formatAsCurrency, formatAsPercentage } from '../../service/format';
import { Legends, Legend, TitleLegend } from '../../component/Charts';
import { useDataProvider } from '../../service/DataProvider/hook';

const filters = [
    { value: 'all', label: 'Tous' },
    { value: 'children', label: 'Enfants' },
    { value: 'adults', label: 'Adultes' },
];

const focus = [
    { value: 'expenditure', label: 'Dépense totale' },
    { value: 'leftover', label: 'Reste à charge' },
    { value: 'quantity', label: 'Fréquentation' },
];

const KaliviaContainer = styled(Container)({
    position: 'relative',
    flexDirection: 'column',
});

const ChartContainer = styled(Container)({
    flexGrow: 1,
    alignItems: 'stretch',
    marginBottom: '1.5rem',

    '& > :first-child': {
        flexBasis: '60%',
    },
});

const AttendanceContainer = styled(Container)({
    flexDirection: 'column',

    '& > *': {
        flexGrow: 0,
        flexShrink: 0,
        margin: '0.5rem 0 0.5rem 0',
    },
});

const AttendanceTitle = styled('h3')(({ theme }) => ({
    color: theme.colors.black,
    marginTop: '-2rem',
}));

const FocusContainer = styled('div')({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1.5rem',

    '& > button': {
        margin: '0 1rem 0 1rem',
    },
});

export class KaliviaFocusView extends Component {
    state = {
        selectedFilter: 'all',
        selectedFocus: 'expenditure',
    };

    handleFilterSelected = selectedFilter => {
        this.setState({ selectedFilter });
    };

    handleFocusSelected = selectedFocus => {
        this.setState({ selectedFocus });
    };

    render() {
        const { data } = this.props;
        const { selectedFocus, selectedFilter } = this.state;

        if (!data) return null;
        const focusedData = getKaliviaDataFromApiResponse(
            data,
            selectedFilter,
            selectedFocus,
        );

        const valueFormatter = (value, label, options) =>
            selectedFocus === 'quantity'
                ? value
                : label === 'Kalivia'
                ? `${formatAsCurrency(value)} (soit ${formatAsPercentage(
                      options.payload.missingPercentage,
                      { showSign: true },
                  )})`
                : formatAsCurrency(value);

        return (
            <KaliviaContainer id="expenditures-kalivia-page">
                <ChartContainer>
                    <ResponsiveContainer width="100%" height={500}>
                        <BarChart
                            data={focusedData}
                            layout="vertical"
                            margin={{ top: 0, right: 150, left: 60, bottom: 0 }}
                        >
                            <Tooltip formatter={valueFormatter} />
                            <ChartLegend content={KaliviaLegend} />
                            <XAxis
                                type="number"
                                tickFormatter={valueFormatter}
                                domain={[0, 'dataMax']}
                            />
                            <YAxis
                                type="category"
                                dataKey="name"
                                padding={{ right: 20 }}
                            />
                            <Bar
                                dataKey="Kalivia"
                                fill={theme.colors.orange}
                                layout="vertical"
                            />
                            <Bar
                                dataKey="Hors Kalivia"
                                fill={theme.colors.blueDark}
                                layout="vertical"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                    <AttendanceContainer>
                        <AttendanceTitle>Fréquentation Kalivia</AttendanceTitle>
                        {focusedData.map(focusedItem => (
                            <Attendance
                                key={focusedItem.name}
                                value={focusedItem.attendance}
                                type={focusedItem.name}
                            />
                        ))}
                    </AttendanceContainer>
                </ChartContainer>
                <FocusContainer>
                    <Legends>
                        {focus.map(({ value, label }) => (
                            <Legend key={value}>
                                <TitleLegend
                                    className={
                                        value === selectedFocus
                                            ? 'selected'
                                            : 'unselected'
                                    }
                                    onClick={() =>
                                        this.handleFocusSelected(value)
                                    }
                                >
                                    {label}
                                </TitleLegend>
                            </Legend>
                        ))}
                    </Legends>
                </FocusContainer>
                <FocusContainer>
                    {filters.map(({ value, label }) => (
                        <Legend key={value}>
                            <TitleLegend
                                accentColor={theme.colors.blueDark}
                                className={
                                    value === selectedFilter
                                        ? 'selected'
                                        : 'unselected'
                                }
                                onClick={() => this.handleFilterSelected(value)}
                            >
                                {label}
                            </TitleLegend>
                        </Legend>
                    ))}
                </FocusContainer>
                {data && <KaliviaDataTable data={data} />}
            </KaliviaContainer>
        );
    }
}

KaliviaFocusView.propTypes = {
    data: PropTypes.object,
};

export const KaliviaFocus = ({ queryFilters }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/kalivia-focus',
        null,
        queryFilters,
    );
    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);
    if (!data) {
        return null;
    }

    return <KaliviaFocusView data={data} />;
};

KaliviaFocus.propTypes = {
    queryFilters: PropTypes.object,
};
