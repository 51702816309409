import React from 'react';
import styled from '@emotion/styled';
import { NavLink, Route } from 'react-router-dom';

export const Tabs = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '2rem',
});

export const TabLink = styled(NavLink)(({ theme, active }) => ({
    color: active ? theme.colors.black : theme.colors.greyShadow2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: 1,

    '& + *': {
        borderLeft: `1px solid ${theme.colors.grey}`,
    },
    padding: '1rem 0 1rem 0',
    textTransform: 'uppercase',
}));

export const Tab = ({
    'aria-current': ariaCurrent,
    exact,
    location,
    strict,
    to,
    ...rest
}) => {
    const path = typeof to === 'object' ? to.pathname : to;

    // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
    const escapedPath =
        path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

    return (
        <Route
            path={escapedPath}
            exact={exact}
            strict={strict}
            location={location}
        >
            {({ match }) => {
                const isActive = !!match;
                return (
                    <TabLink
                        to={to}
                        active={isActive ? 1 : 0}
                        aria-current={(isActive && ariaCurrent) || null}
                        {...rest}
                    />
                );
            }}
        </Route>
    );
};

Tab.propTypes = NavLink.propTypes;
