import styled from '@emotion/styled';

export const Container = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const Title = styled('h1')(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.colors.black,
    fontSize: '1.8rem',
    marginBottom: '2rem',
}));

export const Legends = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.colors.black,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1.5rem',
    marginTop: '2rem',
}));

export const Legend = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 1rem',
});

export const ChipLegend = styled('span')({
    display: 'inline-block',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    backgroundColor: 'red',
    marginRight: '0.5rem',
    paddingBottom: '0.5rem',
});

export const TitleLegend = styled(
    'button',
)(({ theme, accentColor = theme.colors.orange }) => ({
    backgroundColor: theme.colors.white,
    paddingBottom: '0.5rem',
    borderWidth: 0,
    borderBottom: `5px solid ${theme.colors.grey}`,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.colors.black,
    transition: `border-bottom-color ${theme.transitionDuration} ease-out`,
    '&:hover': {
        outline: 0,
        borderBottom: `5px solid ${accentColor}`,
    },
    '&.selected': {
        outline: 0,
        borderBottom: `5px solid ${accentColor}`,
        cursor: 'auto',
    },
}));
