import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Redirect } from 'react-router';

import { Federation } from './Federation';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    margin: '0 5%',
    padding: '0 10%',
});

const Title = styled('h1')(({ theme }) => ({
    color: theme.colors.black,
    display: 'flex',
    flexDirection: 'rows',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: theme.colors.grey,
    fontSize: '1rem',
    padding: '0.5rem 2rem',
    boxShadow: `0px 2px 3px ${theme.colors.greyShadow}`,
}));

export class Federations extends Component {
    state = { redirectToReferrer: false };

    static propTypes = {
        onSetFederation: PropTypes.func.isRequired,
        filters: PropTypes.array,
        location: PropTypes.object,
    };

    handleSelect = federation => {
        this.props.onSetFederation(federation);
        this.setState({ redirectToReferrer: true });
    };

    render() {
        const { filters = [] } = this.props;
        const { redirectToReferrer } = this.state;

        const { from } = this.props.location.state || {
            from: { pathname: '/' },
        };

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }

        return (
            <Container id="federation-page">
                <Title>
                    <span>Fédérations</span> <KeyboardArrowDownIcon />
                </Title>

                <div className="federation-container">
                    {filters.map(federation => (
                        <Federation
                            key={federation.code}
                            federation={federation}
                            onSelect={this.handleSelect}
                        />
                    ))}
                </div>
            </Container>
        );
    }
}
