import styled from '@emotion/styled';

export const HiddenTable = styled('table')({
    left: '-10000px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    top: '-10000px',
    width: '1px',
});
