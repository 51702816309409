import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AgesPyramidChart } from './AgesPyramidChart';
import { getAgePyramidDataFromApiResponse } from './getAgePyramidDataFromApiResponse';
import { useDataProvider } from '../../service/DataProvider/hook';

export const AgesPyramid = ({ queryFilters }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/beneficiaries-ages',
        [],
        queryFilters,
    );
    const [ages, setAges] = useState(null);

    useEffect(() => {
        setAges(getAgePyramidDataFromApiResponse(data));
    }, [data]);

    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);

    if (!ages) {
        return null;
    }

    return (
        <div id="pyramid-page">
            <AgesPyramidChart data={ages} />
        </div>
    );
};

AgesPyramid.propTypes = {
    queryFilters: PropTypes.object.isRequired,
};
