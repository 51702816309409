import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Tooltip } from '../../component/Tooltip';
import departments from './departments.json';
import { formatAsCurrency } from '../../service/format';

const DefinitionList = styled('dl')({
    display: 'flex',
    margin: 0,
});

const DefinitionTerm = styled('dt')({
    marginRight: 20,
});

const DefinitionDefinition = styled('dd')({
    marginLeft: 'auto',
});

export const GeographyTooltip = ({
    data: { department, act_quantity, expenditure, leftover },
}) => (
    <Tooltip>
        <h1>
            {departments[department]} ({department})
        </h1>
        <DefinitionList>
            <DefinitionTerm>Nombre d{"'"}actes</DefinitionTerm>
            <DefinitionDefinition>{act_quantity}</DefinitionDefinition>
        </DefinitionList>
        <DefinitionList>
            <DefinitionTerm>Reste à charge</DefinitionTerm>
            <DefinitionDefinition>
                {formatAsCurrency(leftover / act_quantity)}
            </DefinitionDefinition>
        </DefinitionList>
        <DefinitionList>
            <DefinitionTerm>Dépense</DefinitionTerm>
            <DefinitionDefinition>
                {formatAsCurrency(expenditure / act_quantity)}
            </DefinitionDefinition>
        </DefinitionList>
    </Tooltip>
);

GeographyTooltip.propTypes = {
    data: PropTypes.shape({
        act_quantity: PropTypes.string.isRequired,
        leftover: PropTypes.number.isRequired,
    }).isRequired,
};
