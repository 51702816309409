import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { ChartContainer } from '../../component/Container';

import { theme } from '../../theme';
import { getPercentileDataFromApiResponse } from './getPercentileDataFromApiResponse';
import { PercentileTooltip } from './PercentileTooltip';
import { getExpenditureItemFromLocation } from '../getExpenditureItemFromLocation';
import { formatAsCurrency, formatAsPercentage } from '../../service/format';
import { FocusSelector, FILTERS, OPTICS, DENTAL } from '../FocusSelector';
import { useDataProvider } from '../../service/DataProvider/hook';

class PercentileView extends Component {
    static propTypes = {
        item: PropTypes.oneOf([OPTICS, DENTAL]),
        data: PropTypes.object,
    };

    state = {
        selectedFocus: FILTERS[this.props.item].focus.default,
        selectedFilter:
            FILTERS[this.props.item].filters &&
            FILTERS[this.props.item].filters.default,
    };

    handleSetFocus = selectedFocus => this.setState({ selectedFocus });
    handleSetFilter = selectedFilter => this.setState({ selectedFilter });

    render() {
        const { item, data } = this.props;
        if (!data) return null;

        const { selectedFocus, selectedFilter } = this.state;
        let filteredData = data;

        if (FILTERS[this.props.item].filters) {
            filteredData = data[selectedFilter];
        }

        const focusedData = getPercentileDataFromApiResponse(
            filteredData[selectedFocus],
        );

        const focusItems = FILTERS[item].focus.items;

        return (
            <ChartContainer id="expenditures-percentile-page">
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart
                        data={focusedData}
                        margin={{ top: 0, right: 150, left: 60, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={PercentileTooltip} />
                        <XAxis
                            dataKey="name"
                            interval={9}
                            tickFormatter={value => formatAsPercentage(value)}
                            padding={{ right: 20 }}
                        />
                        <YAxis
                            dataKey="value"
                            tickFormatter={value =>
                                formatAsCurrency(Math.floor(value), false)
                            }
                        />
                        <Line
                            dataKey="value"
                            dot={false}
                            activeDot={{
                                fill: theme.colors.orange,
                                strokeWidth: 4,
                                r: 6,
                            }}
                            fill={theme.colors.orange}
                            strokeWidth={2}
                        />
                    </LineChart>
                </ResponsiveContainer>
                <FocusSelector
                    focuses={focusItems}
                    filters={
                        FILTERS[item].filters && FILTERS[item].filters.items
                    }
                    onSetFocus={this.handleSetFocus}
                    selectedFocus={selectedFocus}
                    onSetFilter={this.handleSetFilter}
                    selectedFilter={selectedFilter}
                />
            </ChartContainer>
        );
    }
}

export const Percentile = ({ queryFilters, location }) => {
    const [type, setType] = useState(getExpenditureItemFromLocation(location));
    useEffect(() => {
        setType(getExpenditureItemFromLocation(location));
    }, [location]);

    const [finalFilters, setFinalFilters] = useState({
        ...queryFilters,
        type,
    });
    useEffect(() => {
        setFinalFilters({
            ...queryFilters,
            type,
        });
    }, [queryFilters, type]);

    const [data, updateQueryFilters] = useDataProvider(
        '/api/percentile',
        null,
        finalFilters,
    );
    useEffect(() => {
        updateQueryFilters(finalFilters);
    }, [finalFilters]);

    if (!data) {
        return null;
    }
    return <PercentileView data={data} item={type} />;
};

Percentile.propTypes = {
    queryFilters: PropTypes.object,
    location: PropTypes.object.isRequired,
};
