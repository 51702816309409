import { stringify } from 'query-string';

export const prepareCompaniesFilter = filters => {
    if (!filters || !Array.isArray(filters) || !filters.length) {
        return null;
    }
    return filters.reduce((acc, filter) => {
        const [companyCode, productCode] = filter.split('*');
        let corp = acc.find(c => c.code === companyCode);
        if (!corp || !corp.code) {
            corp = {
                code: companyCode,
                products: [],
            };
        }
        corp.products.push(productCode);
        return [...acc.filter(c => c.code !== corp.code), corp];
    }, []);
};

const generateQueryFilters = (federation, filters) => {
    const rawParams = {
        federation: federation.code,
        ...filters,
        companies: filters.companies
            ? JSON.stringify(prepareCompaniesFilter(filters.companies))
            : null,
    };

    // do not send empty value as query params
    return stringify(
        Object.keys(rawParams)
            .filter(key => rawParams[key])
            .reduce((acc, key) => {
                acc[key] = rawParams[key];
                return acc;
            }, {}),
        { arrayFormat: 'bracket' },
    );
};

export const generateApiCall = (url, federation, filters = {}) => {
    const queryFilters = generateQueryFilters(federation, filters);
    return `${url}${queryFilters ? `?${queryFilters}` : ''}`;
};
