export const getInitialState = (configuration = {}) => ({
    apikey: configuration.apikey || null,
    federation: null,
    filters: null,
    lastImportDate: null,
    loginRedirection: configuration.loginUrl || null,
    user: null,
    userHasBeenChecked: false,
});

export const USER_AUTHENTICATED = 'authenticated';
export const USER_NOT_AUTHENTICATED = 'notAuthenticated';
export const USER_LOGOUT = 'logout';
export const USER_CHOOSE_FEDERATION = 'chooseFederation';
export const USER_RESET_FEDERATION = 'resetFederation';

export const reducer = (state, action) => {
    switch (action.type) {
        case USER_AUTHENTICATED:
            return {
                ...state,
                federation: action.payload.federation
                    ? action.payload.federation
                    : action.payload.filters.length === 1
                    ? {
                          code: action.payload.filters[0].code,
                          label: action.payload.filters[0].label,
                      }
                    : null,
                filters: action.payload.filters,
                lastImportDate: action.payload.lastImportDate,
                user: action.payload.user,
                userHasBeenChecked: true,
            };
        case USER_NOT_AUTHENTICATED:
            return {
                ...state,
                userHasBeenChecked: true,
            };
        case USER_LOGOUT:
            return {
                ...state,
                federation: null,
                filters: null,
                user: null,
                userHasBeenChecked: true,
            };
        case USER_CHOOSE_FEDERATION:
            return {
                ...state,
                federation: action.payload.federation,
            };
        case USER_RESET_FEDERATION:
            return {
                ...state,
                federation: null,
            };
        default:
            throw new Error();
    }
};
