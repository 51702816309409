import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';

import { FiltersProvider } from '../filters/FiltersProvider';
import { BeneficiariesNavigation } from './BeneficiariesNavigation';
import { Distribution } from './distribution/Distribution';
import { AgesPyramid } from './agesPyramid/AgesPyramid';
import { FamilyComposition } from './familyComposition/FamilyComposition';
import { Monthly } from './monthly/Monthly';
import { NotFound } from '../NotFound';
import { SectionContainer } from '../component/Container';

export const Beneficiaries = ({
    history,
    federation,
    location,
    match,
    user,
}) => (
    <FiltersProvider
        location={location}
        history={history}
        user={user}
        federation={federation}
        render={({ filters, onLoading }) => (
            <Fragment>
                <BeneficiariesNavigation user={user} />
                <SectionContainer>
                    <Switch>
                        <Route
                            path={`${match.url}/pyramid`}
                            render={({ location }) => (
                                <AgesPyramid queryFilters={filters} />
                            )}
                        />
                        <Route
                            path={`${match.url}/family-composition`}
                            render={({ location }) => (
                                <FamilyComposition queryFilters={filters} />
                            )}
                        />
                        <Route
                            path={`${match.url}/monthly`}
                            render={() => <Monthly queryFilters={filters} />}
                        />
                        <Route
                            path={match.url}
                            exact
                            render={({ location }) => (
                                <Distribution queryFilters={filters} />
                            )}
                        />
                        <Route render={() => <NotFound />} />
                    </Switch>
                </SectionContainer>
            </Fragment>
        )}
    />
);

Beneficiaries.propTypes = {
    federation: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};
