import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { PrivateRoute } from './PrivateRoute';

/**
 * This Route will redirect the user to the federation selection page if needed:
 * user is a salesman and have not yet selected a federation
 */
export const FederationRoute = ({ user, federation, ...rest }) => (
    <PrivateRoute
        {...rest}
        user={user}
        render={props => {
            if (user && !federation) {
                return (
                    <Redirect
                        to={{
                            pathname: '/federations',
                            state: { from: props.location },
                        }}
                    />
                );
            }

            return <Route {...rest} />;
        }}
    />
);

FederationRoute.propTypes = {
    federation: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
};
