import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { Tabs, Tab } from '../component/Tabs';

export const BeneficiariesNavigation = ({ user }) => (
    <Route
        render={({ location: { search } }) => (
            <Tabs>
                <Tab
                    data-test="navigate-beneficiaries-distribution"
                    to={{ pathname: '/beneficiaries', search }}
                    exact
                >
                    Repartition
                </Tab>
                <Tab
                    data-test="navigate-beneficiaries-pyramid"
                    to={{ pathname: '/beneficiaries/pyramid', search }}
                    exact
                >
                    Pyramide des ages
                </Tab>
                <Tab
                    data-test="navigate-beneficiaries-monthly"
                    to={{ pathname: '/beneficiaries/monthly', search }}
                    exact
                >
                    Par mois
                </Tab>
                {user.role === 'commercial_hm' && (
                    <Tab
                        data-test="navigate-beneficiaries-family-composition"
                        to={{
                            pathname: '/beneficiaries/family-composition',
                            search,
                        }}
                        exact
                    >
                        Composition familiale
                    </Tab>
                )}
            </Tabs>
        )}
    />
);

BeneficiariesNavigation.propTypes = {
    user: PropTypes.object,
};
