import d3Tip from 'd3-tip';
import React from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { formatAsCurrency } from '../service/format';

const styles = {
    tooltip: {
        lineHeight: 1,
        fontWeight: 'bold',
        padding: '0.5rem',
        background: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        borderRadius: '2px',
        pointerEvents: 'none',
    },
    tooltipAfter: {
        content: ' ',
        height: 0,
        width: 0,
        position: 'absolute',
        left: '50%',
        top: '100%',
        border: '10px solid transparent',
        marginLeft: '-10px',
        borderTopColor: 'rgba(0, 0, 0, 0.8)',
    },
};

const DefaultTooltipComponent = ({ data, yScale, dictionary = {} }) => (
    <div style={styles.tooltip}>
        {dictionary[data.key] || data.key}{' '}
        {formatAsCurrency(yScale(data[0]) - yScale(data[1]))}
        <span style={styles.tooltipAfter} />
    </div>
);

DefaultTooltipComponent.propTypes = {
    data: PropTypes.any.isRequired,
    yScale: PropTypes.func.isRequired,
    dictionary: PropTypes.any,
};

const DefaultToolTip = DefaultTooltipComponent;

export default ({ yScale, ToolTip = DefaultToolTip, dictionary }) =>
    d3Tip()
        .attr('class', 'd3-tip')
        .style('z-index', '1000')
        .offset([-8, 0])
        .html(data => {
            if (!data) {
                return;
            }
            return renderToString(
                <ToolTip data={data} yScale={yScale} dictionary={dictionary} />,
            );
        });
