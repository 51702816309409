import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button } from '../component/Button';

const FederationItem = styled('div')(({ theme }) => ({
    color: theme.colors.black,
    display: 'flex',
    flexDirection: 'rows',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    fontSize: '1rem',
    boxShadow: `0px 2px 3px ${theme.colors.greyShadow}`,
    padding: '1rem 2rem',
    margin: '0.5rem 0',
    transition: 'color 0.5s',
    '&:hover': {
        color: theme.colors.blueDark,
    },
}));

export class Federation extends Component {
    static propTypes = {
        federation: PropTypes.shape({
            label: PropTypes.string.isRequired,
            code: PropTypes.number.isRequired,
        }).isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    handleClick = () => {
        const { federation, onSelect } = this.props;
        onSelect(federation);
    };

    render() {
        const { federation } = this.props;
        return (
            <FederationItem className="federation-item">
                <div>{federation.label}</div>
                <Button
                    id={`select-${federation.code}`}
                    onClick={this.handleClick}
                >
                    Visualiser
                </Button>
            </FederationItem>
        );
    }
}
