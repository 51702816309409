export const getKaliviaDataFromApiResponse = (data, filter, focus) =>
    [
        'Verres simples',
        'Verres complexes',
        'Verres très complexes',
        'Montures',
    ].map((name, index) => {
        const filteredData = data[filter];

        const kalivia = parseFloat(
            filteredData[index] && filteredData[index].length
                ? (filteredData[index].find(d => d && d.inKalivia) || {})[focus] || 0
                : 0,
        );

        const kaliviaQuantity =
                filteredData[index] && filteredData[index].length ?
            (filteredData[index].find(d => d && d.inKalivia) || {}).quantity || 0 : 0;
        const othersQuantity =
                filteredData[index] && filteredData[index].length ?
            (filteredData[index].find(d => d && !d.inKalivia) || {}).quantity || 0 : 0;

        const others = parseFloat(
            filteredData[index] && filteredData[index].length
                ? (filteredData[index].find(d => d && !d.inKalivia) || {})[focus] ||
                  0
                : 0,
        );
        const difference = others - kalivia;
        const missingPercentage = -(difference ? difference / others : 0);
        return {
            name,
            attendance:
                kaliviaQuantity > 0
                    ? kaliviaQuantity / (kaliviaQuantity + othersQuantity)
                    : 0,
            Kalivia: kalivia,
            missingPercentage: isFinite(missingPercentage)
                ? missingPercentage
                : 0,
            'Hors Kalivia': others,
        };
    });
