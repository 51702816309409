import styled from '@emotion/styled';

export const Tooltip = styled('div')({
    lineHeight: 1,
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    borderRadius: '2px',
    pointerEvents: 'none',
    zIndex: 10,

    '& h1': {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        margin: '0 0 1rem 0',
    },

    '&::after': {
        content: 'none',
        height: 0,
        width: 0,
        position: 'absolute',
        top: '50%',
        right: '100%',
        border: '10px solid transparent',
        marginLeft: '-10px',
        borderRightColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 10,
    },
});
