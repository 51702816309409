import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ChartContainer } from '../../component/Container';
import { GeographyChart } from './GeographyChart';
import { GeographyDataTable } from './GeographyDataTable';
import { FocusSelector, FILTERS, OPTICS, DENTAL } from '../FocusSelector';
import { getGeograpyDataFromApiResponse } from './getGeograpyDataFromApiResponse';
import { getExpenditureItemFromLocation } from '../getExpenditureItemFromLocation';
import { useDataProvider } from '../../service/DataProvider/hook';

class GeographyView extends Component {
    static propTypes = {
        item: PropTypes.oneOf([OPTICS, DENTAL]),
        data: PropTypes.object,
    };

    state = {
        selectedFocus: FILTERS[this.props.item].focus.default,
        selectedFilter:
            FILTERS[this.props.item].filters &&
            FILTERS[this.props.item].filters.default,
    };

    handleSetFilter = selectedFilter => this.setState({ selectedFilter });
    handleSetFocus = selectedFocus => this.setState({ selectedFocus });

    render() {
        const { item, data } = this.props;

        if (!data) return null;

        const { selectedFocus, selectedFilter } = this.state;
        let filteredData = data;

        if (FILTERS[this.props.item].filters) {
            filteredData = data[selectedFilter];
        }

        const focusedData = getGeograpyDataFromApiResponse(
            filteredData[selectedFocus],
        );
        const focusItems = FILTERS[item].focus.items;

        return (
            <ChartContainer>
                <GeographyChart height={500} data={focusedData} />

                <FocusSelector
                    focuses={focusItems}
                    filters={
                        FILTERS[item].filters && FILTERS[item].filters.items
                    }
                    onSetFocus={this.handleSetFocus}
                    selectedFocus={selectedFocus}
                    onSetFilter={this.handleSetFilter}
                    selectedFilter={selectedFilter}
                />
                <GeographyDataTable
                    data={data}
                    filterLevels={FILTERS[this.props.item].filters ? 2 : 1}
                />
            </ChartContainer>
        );
    }
}

GeographyView.propTypes = {
    data: PropTypes.object,
};

export const Geography = ({ queryFilters, location }) => {
    const [type, setType] = useState(getExpenditureItemFromLocation(location));
    useEffect(() => {
        setType(getExpenditureItemFromLocation(location));
    }, [location]);

    const [finalFilters, setFinalFilters] = useState({
        ...queryFilters,
        type,
    });
    useEffect(() => {
        setFinalFilters({
            ...queryFilters,
            type,
        });
    }, [queryFilters, type]);

    const [data, updateQueryFilters] = useDataProvider(
        '/api/expenditure-map',
        null,
        finalFilters,
    );
    useEffect(() => {
        updateQueryFilters(finalFilters);
    }, [finalFilters]);

    if (!data) {
        return null;
    }
    return <GeographyView data={data} item={type} />;
};

Geography.propTypes = {
    queryFilter: PropTypes.object,
    location: PropTypes.object.isRequired,
};
