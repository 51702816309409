import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { formatAsPercentage, formatAsCurrency } from '../../service/format';

const PercentileValue = styled('span')(({ theme }) => ({
    color: theme.colors.blueDark,
}));

const PercentilePercentage = styled('span')(({ theme }) => ({
    color: theme.colors.black,
    margin: '0 0 0 1rem',
}));

const PercentileTooltipContainer = styled('div')({
    margin: '0px',
    padding: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid rgb(204, 204, 204)',
    whiteSpace: 'nowrap',

    '& p': {
        margin: '0px',
    },
});

export const PercentileTooltip = ({ payload, label }) => {
    if (!payload || !payload.length) return null;
    const value = formatAsCurrency(payload[0].value);
    const percentage = formatAsPercentage(label);

    return (
        <PercentileTooltipContainer className="recharts-default-tooltip">
            <p className="recharts-tooltip-label">
                <PercentileValue>{value}</PercentileValue>
                <PercentilePercentage>{percentage}</PercentilePercentage>
            </p>
        </PercentileTooltipContainer>
    );
};

PercentileTooltip.propTypes = {
    payload: PropTypes.array,
    label: PropTypes.string,
};
