import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { formatAsCurrency } from '../../service/format';
import { Tooltip } from '../../component/Tooltip';

const DefinitionList = styled('dl')({
    display: 'flex',
    margin: 0,
});

const DefinitionTerm = styled('dt')({
    marginRight: 20,
});

const DefinitionDefinition = styled('dd')({
    marginLeft: 'auto',
});

export const SunburstTooltip = props => {
    const { data: { data: { name }, value } } = props;
    return (
        <Tooltip>
            <h1>{name}</h1>
            <DefinitionList>
                <DefinitionTerm>Dépense totale</DefinitionTerm>
                <DefinitionDefinition>
                    {formatAsCurrency(value)}
                </DefinitionDefinition>
            </DefinitionList>
        </Tooltip>
    );
};

SunburstTooltip.propTypes = {
    data: PropTypes.object.isRequired,
};
