import omit from 'lodash/omit';
import flatten from 'lodash/flatten';

import { combineData } from './combineData';

const filterData = (filter, data) => {
    if (!data) {
        return 0;
    }

    const filteredData = Object.keys(data).map(key => data[key]); // first filter at the group level
    return combineData(flatten(filteredData)); // we got an array of arrays of data, so we flatten the arrays before combining the data
};

/**
 * Take a tree and a filter and return a filtered tree
 * The filter is applied to the data object on the leaf of the tree
 * The data object represent the data of provisions grouped by group and then by dates :
 * {
 *      group1: {
 *          date1: {
 *              repayment: 42
 *          },
 *          date2: {
 *              repayment: 19
 *          }
 *      },
 *      group2...
 * }
 * This function traverse the tree, then using the filter values return the combination of the filtered data.
 * {
 *      data: {
 *          repayment: 'The sum of all data.payment not filtered out'
 *      }
 * }
 */
export const filterTree = tree => filter => {
    const loop = subTree => {
        // traverse the tree until we encounter child with a data property.
        if (!subTree.data) {
            return {
                ...subTree,
                children: subTree.children.map(loop),
            };
        }

        const data = filterData(filter, subTree.data);

        if (subTree.children && subTree.children.length) {
            return {
                ...omit(subTree, ['data', 'children']),
                data,
                children: subTree.children.map(loop),
            };
        }

        return {
            ...omit(subTree, ['data']),
            data,
        };
    };

    const result = loop(tree);

    return result;
};
