export const writeToCache = (url, data) => {
    sessionStorage.setItem(url, JSON.stringify(data));
};

export const readFromCache = url => {
    const serializedData = sessionStorage.getItem(url);

    if (serializedData) {
        return JSON.parse(serializedData);
    }

    return undefined;
};
