import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ListItem = styled('li')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Link = styled('button')(({ fillColor, first, last, theme }) => ({
    backgroundColor: fillColor,
    border: fillColor,
    color: theme.colors.black,
    cursor: 'pointer',
    fontSize: '1.1rem',
    margin: 0,
    maxWidth: first || last ? 'unset' : '50px',
    padding: `.5rem 0 .7rem ${first ? '1rem' : '1.5rem'}`,
    position: 'relative',
    textShadow: '1px 1px 0 rgba(0,0,0,.3)',
    pointerEvents: last ? 'none' : 'auto',

    '&::before': {
        content: '" "',
        display: 'block',
        width: 0,
        height: 0,
        borderTop: '1.5rem solid transparent',
        borderBottom: '1.5rem solid transparent',
        borderLeft: '1rem solid white',
        position: 'absolute',
        top: '50%',
        marginTop: '-1.5rem',
        marginLeft: '1px',
        left: '100%',
        zIndex: 1,
    },
    '&::after': {
        borderLeft: `1rem solid ${fillColor}`,
        borderTop: '1.5rem solid transparent',
        borderBottom: '1.5rem solid transparent',
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: '50%',
        marginTop: '-1.5rem',
        left: '100%',
        width: 0,
        height: 0,
        zIndex: 2,
    },
}));

const ItemText = styled('div')({
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const BreadcrumbItem = ({
    name,
    color: { h: hue, s: saturation, l: luminance },
    first,
    last,
    onClick,
}) => {
    const fillColor =
        name === 'Santé'
            ? '#eee'
            : `hsl(${Math.round(hue)},${Math.round(
                  saturation * 100,
              )}%,${Math.round(luminance * 100)}%)`;

    return (
        <ListItem>
            <Link
                first={first}
                last={last}
                fillColor={fillColor}
                onClick={onClick}
                title={name}
            >
                <ItemText first={first} fillColor={fillColor}>
                    {first || last ? name : name[0]}
                </ItemText>
            </Link>
        </ListItem>
    );
};

BreadcrumbItem.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.shape({
        h: PropTypes.number,
        s: PropTypes.number,
        l: PropTypes.number,
    }),
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
