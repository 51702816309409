import fetchPolyfill from 'isomorphic-fetch';
import { getConfigurationPerHostname } from '../configuration';

const hostname =
    typeof window !== 'undefined' ? window.location.hostname : 'localhost';
const configuration = getConfigurationPerHostname(hostname);

export const parseResponse = response => {
    if (response.status === 204) {
        return null;
    }
    if (response.status >= 200 && response.status < 300) {
        return response.json();
    }
    const error = new Error(response.statusText);
    error.response = response;
    error.code = response.status;

    throw error;
};

export const fetch = ({ url, apikey = null, ...rest }) =>
    fetchPolyfill(`${configuration.apiHost}${url}`, {
        ...rest,
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            apikey,
        },
    }).then(parseResponse);
