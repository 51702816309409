import styled from '@emotion/styled';

export const Container = styled('div')({
    display: 'flex',
});

export const ChartContainer = styled(Container)({
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
});

export const SectionContainer = styled('div')({
    marginTop: '2rem',
    marginLeft: '2rem',
});
