import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isEqual from 'lodash/isEqual';
import { HiddenTable } from '../../component/HiddenTable';

import { ExpenditurePerBeneficiaryChart } from './ExpenditurePerBeneficiaryChart';
import { getExpenditureItemFromLocation } from '../getExpenditureItemFromLocation';
import {
    ChipLegend,
    Container,
    Legend,
    Legends,
    Title,
    TitleLegend,
} from '../../component/Charts';
import { theme } from '../../theme';
import { useDataProvider } from '../../service/DataProvider/hook';

const MandatoryChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.blue,
}));
const ComplementaryChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.blueDark,
}));
const ChargeableChip = styled(ChipLegend)(({ theme }) => ({
    backgroundColor: theme.colors.orange,
}));

const FocusContainer = styled('div')({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1.5rem',

    '& > button': {
        margin: '0 1rem 0 1rem',
    },
});

const getTitle = (filter, viewType, lastMonth) => {
    const byBeneficiary = `par bénéficiaire ${
        viewType === 'year'
            ? 'par an'
            : `pour la période de janvier à ${lastMonth || ''}`
    }`;
    switch (filter) {
        case 'ob':
            return `Régime obligatoire ${byBeneficiary}`;
        case 'cp':
            return `Régime complémentaire ${byBeneficiary}`;
        case 'ac':
            return `Reste à charge ${byBeneficiary}`;
        default:
            return `Dépense totale ${byBeneficiary}`;
    }
};

const getFilteredData = (data, filter) => {
    if (!filter) {
        return data;
    }

    return data.map(d => ({
        ...d,
        ...{ ob: 0.001, ac: 0.001, cp: 0.001 },
        [filter]: d[filter],
    }));
};

export class ExpenditurePerBeneficiaryView extends Component {
    static propTypes = {
        data: PropTypes.array,
        onChangeViewType: PropTypes.func.isRequired,
        currentViewType: PropTypes.string.isRequired,
        viewTypes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
    };

    state = {
        filter: null,
        title: getTitle(null, 'period'),
        data: [],
    };

    componentDidMount() {
        this.setState({
            data: this.props.data,
            title: getTitle(
                this.state.filter,
                this.props.currentViewType,
                this.props.data && this.props.data[0]
                    ? this.props.data[0].periodEnd
                    : null,
            ),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (
            !isEqual(nextProps.data, this.props.data) ||
            !isEqual(nextProps.currentViewType, this.props.currentViewType)
        ) {
            this.setState({
                data: getFilteredData(nextProps.data, this.state.filter),
                title: getTitle(
                    this.state.filter,
                    nextProps.currentViewType,
                    nextProps.data[0].periodEnd,
                ),
            });
        }
    }

    handleSelectFilter = (filter = null) =>
        this.setState({
            filter,
            title: getTitle(
                filter,
                this.props.currentViewType,
                this.props.data[0].periodEnd,
            ),
            data: getFilteredData(this.props.data, filter),
        });

    render() {
        const { filter, title, data } = this.state;
        const { currentViewType, viewTypes } = this.props;
        return (
            <Container id="expenditures-per-beneficiary-page">
                <Title>{title}</Title>
                <ExpenditurePerBeneficiaryChart data={data} filter={filter} />
                <Legends>
                    <Legend>
                        <TitleLegend
                            className={!filter ? 'selected' : 'unselected'}
                            onClick={() => this.handleSelectFilter()}
                        >
                            Dépense totale
                        </TitleLegend>
                    </Legend>
                    <Legend>
                        <MandatoryChip />
                        <TitleLegend
                            className={
                                filter === 'ob' ? 'selected' : 'unselected'
                            }
                            onClick={() => this.handleSelectFilter('ob')}
                        >
                            Régime obligatoire
                        </TitleLegend>
                    </Legend>
                    <Legend>
                        <ComplementaryChip />
                        <TitleLegend
                            className={
                                filter === 'cp' ? 'selected' : 'unselected'
                            }
                            onClick={() => this.handleSelectFilter('cp')}
                        >
                            Régime complémentaire
                        </TitleLegend>
                    </Legend>
                    <Legend>
                        <ChargeableChip />
                        <TitleLegend
                            className={
                                filter === 'ac' ? 'selected' : 'unselected'
                            }
                            onClick={() => this.handleSelectFilter('ac')}
                        >
                            Reste à charge
                        </TitleLegend>
                    </Legend>
                </Legends>
                <FocusContainer>
                    {viewTypes.map(({ value, label }) => (
                        <Legend key={value}>
                            <TitleLegend
                                accentColor={theme.colors.blueDark}
                                className={
                                    value === currentViewType
                                        ? 'selected'
                                        : 'unselected'
                                }
                                onClick={() =>
                                    this.props.onChangeViewType(value)
                                }
                            >
                                {label}
                            </TitleLegend>
                        </Legend>
                    ))}
                </FocusContainer>
                <HiddenTable>
                    <thead>
                        <tr>
                            <th>Année</th>
                            <th>Régime obligatoire</th>
                            <th>Régime complémentaire</th>
                            <th>Reste à charge</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map(section => (
                                <tr key={section.name} data-test="beneficiary">
                                    <td data-test="year">{section.name}</td>
                                    <td data-test="ac">{section.ac}</td>
                                    <td data-test="cp">{section.cp}</td>
                                    <td data-test="ob">{section.ob}</td>
                                    <td data-test="total">{section.total}</td>
                                </tr>
                            ))}
                    </tbody>
                </HiddenTable>
            </Container>
        );
    }
}

export const ExpenditurePerBeneficiary = ({ queryFilters, location }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/expenditures',
        [],
        queryFilters,
    );
    const [viewType, setViewType] = useState('period');

    const [finalFilters, setFinalFilters] = useState({
        ...queryFilters,
        item: getExpenditureItemFromLocation(location) || null,
        year: viewType === 'year' ? true : null,
    });

    useEffect(
        () =>
            setFinalFilters({
                ...queryFilters,
                item: getExpenditureItemFromLocation(location) || null,
                year: viewType === 'year' ? true : null,
            }),
        [queryFilters, location, viewType],
    );

    useEffect(() => {
        updateQueryFilters(finalFilters);
    }, [finalFilters]);

    const viewTypes = [
        { label: 'Par période', value: 'period' },
        { label: 'Par année', value: 'year' },
    ];
    return (
        <ExpenditurePerBeneficiaryView
            currentViewType={viewType}
            data={data}
            viewTypes={viewTypes}
            onChangeViewType={setViewType}
        />
    );
};

ExpenditurePerBeneficiary.propTypes = {
    queryFilters: PropTypes.object,
    location: PropTypes.object.isRequired,
};
