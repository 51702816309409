import React from 'react';

export const Icon = () => (
    <svg
        width="86px"
        height="30px"
        viewBox="0 0 86 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Optique</title>
        <g
            id="ACCUEIL"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            transform="translate(-833.000000, -669.000000)"
        >
            <g
                id="noun_742229_cc"
                transform="translate(833.000000, 669.000000)"
                fill="#000000"
                fillRule="nonzero"
            >
                <path
                    d="M81.32,2.71 C68.001,0.371 63.911,0.244 50.166,2.328 C48.66,2.557 46.438,3.275 44.821,3.275 C38.752,3.275 41.866,3.347 36.234,2.423 C20.779,0.227 17.234,0.742 2.689,3.037 C1.874,3.166 0.774,3.275 0.774,4.102 C0.774,8.094 3.522,6.878 5.108,10.863 C6.312,13.889 5.4,16.441 6.587,20.148 C7.43,22.781 8.982,24.954 11.174,26.324 C17.409,30.221 31.413,30.461 36.023,24.283 C39.359,19.812 38.609,16.086 39.619,11.651 C40.297,8.673 41.639,8.904 43.827,8.904 C48.068,8.904 45.282,17.953 49.977,24.281 C50.376,24.819 50.734,25.235 51.254,25.653 C56.804,30.115 68.428,30.229 74.724,26.387 C81.431,22.293 79.538,14.322 80.844,10.98 C82.467,6.826 85.225,8.074 85.225,4.1 C85.226,3.039 82.779,2.967 81.32,2.71 Z M30.55,23.282 C25.969,26.241 15.615,25.284 11.578,21.286 C10.973,20.687 10.734,20.375 10.32,19.563 C9.093,17.159 9.385,13.605 9.385,10.562 C9.385,5.333 13.756,4.27 18.327,4.27 C25.962,4.27 35.217,3.124 35.217,12.715 C35.217,17.394 33.802,21.182 30.55,23.282 Z M76.615,14.205 C76.615,24.597 65.802,26.204 57.934,24.275 C54.818,23.511 52.581,21.275 51.647,18.308 C51.208,16.912 50.782,14.8 50.782,13.046 C50.782,9.781 51.538,7.537 53.524,6.184 C57.059,3.777 63.27,4.27 67.672,4.27 C69.881,4.27 72.234,4.67 74.077,5.648 C77.13,7.268 76.615,10.471 76.615,14.205 Z"
                    id="Shape"
                />
            </g>
        </g>
    </svg>
);
