// combining this data object with another will have no effect
export const neutralData = {
    rc_repayment: 0,
    ro_repayment: 0,
    other_organization_repayment: 0,
    expenditure: 0,
    act_quantity: 0,
};

export const combineTwoData = (a, b) => {
    const rc_repayment = a.rc_repayment + b.rc_repayment;
    const ro_repayment = a.ro_repayment + b.ro_repayment;
    const other_organization_repayment =
        a.other_organization_repayment + b.other_organization_repayment;
    const expenditure = a.expenditure + b.expenditure;
    const act_quantity =
        parseInt(a.act_quantity, 10) + parseInt(b.act_quantity, 10);

    const leftover =
        expenditure -
        (rc_repayment + ro_repayment + other_organization_repayment);

    return {
        rc_repayment,
        ro_repayment,
        other_organization_repayment,
        expenditure,
        act_quantity,
        leftover,
    };
};

export const combineData = (arrayData = [neutralData]) => {
    return arrayData.reduce(combineTwoData, neutralData);
};
