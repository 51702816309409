import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { WomanIcon } from './WomanIcon';
import { ManIcon } from './ManIcon';
import { GirlIcon } from './GirlIcon';
import { BoyIcon } from './BoyIcon';

const labels = {
    singlesWithoutChildren: 'Célibataire(s) sans enfants',
    singlesWithSingleChild: 'Célibataire(s) avec un enfant',
    singlesWithTwoChildren: 'Célibataire(s) avec deux enfants',
    singlesWithManyChildren: 'Célibataire(s) avec plus de deux enfants',
    couplesWithoutChildren: 'Couple(s) sans enfants',
    couplesWithSingleChild: 'Couple(s) avec un enfant',
    couplesWithTwoChildren: 'Couple(s) avec deux enfants',
    couplesWithManyChildren: 'Couple(s) avec plus de deux enfants',
};

const IconsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const SinglesWithoutChildren = () => (
    <IconsContainer>
        <ManIcon />
    </IconsContainer>
);

const SinglesWithSingleChild = () => (
    <IconsContainer>
        <WomanIcon />
        <BoyIcon />
    </IconsContainer>
);

const SinglesWithTwoChildren = () => (
    <IconsContainer>
        <WomanIcon />
        <BoyIcon />
        <GirlIcon />
    </IconsContainer>
);

const SinglesWithManyChildren = () => (
    <IconsContainer>
        <WomanIcon />
        <BoyIcon />
        <GirlIcon />
        <GirlIcon />
    </IconsContainer>
);

const CouplesWithoutChildren = () => (
    <IconsContainer>
        <ManIcon />
        <WomanIcon />
    </IconsContainer>
);

const CouplesWithSingleChild = () => (
    <IconsContainer>
        <ManIcon />
        <WomanIcon />
        <BoyIcon />
    </IconsContainer>
);

const CouplesWithTwoChildren = () => (
    <IconsContainer>
        <ManIcon />
        <WomanIcon />
        <BoyIcon />
        <GirlIcon />
    </IconsContainer>
);

const CouplesWithManyChildren = () => (
    <IconsContainer>
        <ManIcon />
        <WomanIcon />
        <BoyIcon />
        <GirlIcon />
        <GirlIcon />
    </IconsContainer>
);

const icons = {
    singlesWithoutChildren: SinglesWithoutChildren,
    singlesWithSingleChild: SinglesWithSingleChild,
    singlesWithTwoChildren: SinglesWithTwoChildren,
    singlesWithManyChildren: SinglesWithManyChildren,
    couplesWithoutChildren: CouplesWithoutChildren,
    couplesWithSingleChild: CouplesWithSingleChild,
    couplesWithTwoChildren: CouplesWithTwoChildren,
    couplesWithManyChildren: CouplesWithManyChildren,
};

const CompositionContainer = styled('div')({
    alignItems: 'start',
    justifyContent: 'center',
    display: 'flex',
    flexBasis: '40%',
    flexDirection: 'column',
    margin: '0.5rem 0 0.5rem',

    '&:nth-child(odd)': {
        marginLeft: '15%',
    },
});

const CompositionValueContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const CompositionLabel = styled('div')(({ theme }) => ({
    color: theme.colors.greyShadow2,
    fontSize: '0.8rem',
    padding: '0.5rem 0.5rem 0.5rem 0',
    textAlign: 'center',
    textTransform: 'uppercase',
}));

const CompositionValue = styled('div')(({ theme }) => ({
    color: theme.colors.black,
    fontSize: '4rem',
    padding: '0.5rem',
    textAlign: 'center',
}));

export const Composition = ({ name, value }) => {
    const Icon = icons[name];

    return (
        <CompositionContainer data-test="family-composition">
            <CompositionValueContainer>
                <CompositionValue data-test="value">{value}</CompositionValue>
                <Icon />
            </CompositionValueContainer>
            <CompositionLabel data-test="name">{labels[name]}</CompositionLabel>
        </CompositionContainer>
    );
};

Composition.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};
