import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../theme';

import {
    SocketedData,
    SocketedDataLabel,
    SocketedDataType,
} from '../../component/Socket';

const colorsByTypes = {
    adherent: theme.colors.orange,
    conjoint: theme.colors.blueDark,
    enfant: theme.colors.blue,
};

const labelByTypes = {
    adherent: 'Adhérents',
    conjoint: 'Conjoints',
    enfant: 'Enfants',
};

export const AverageAge = ({ type, value }) => (
    <SocketedData
        color={colorsByTypes[type]}
        renderValue={() => (
            <Fragment>
                <div data-test="average-age">{value}</div>
                <div>Ans</div>
            </Fragment>
        )}
        renderLabel={() => (
            <Fragment>
                <SocketedDataLabel>Age moyen</SocketedDataLabel>
                <SocketedDataType>{labelByTypes[type]}</SocketedDataType>
            </Fragment>
        )}
    />
);

AverageAge.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};
