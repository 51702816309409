import React from 'react';
import PropTypes from 'prop-types';

import { HiddenTable } from '../../component/HiddenTable';
import { formatAsCurrency } from '../../service/format';

const flatten = (data, path = []) => {
    if (data.children) {
        return data.children.reduce(
            (acc, child) => [...acc, ...flatten(child, [...path, data.name])],
            [],
        );
    }

    return [
        {
            path: [...path, data.name].join(' - '),
            ...Object.keys(data.data).reduce((acc, key) => data.data[key], {}),
        },
    ];
};

export const ExpenditurePerItemDataTable = ({ data }) => {
    const flattenedData = flatten(data);
    return (
        <HiddenTable>
            <thead>
                <tr>
                    <th>Poste de soin</th>
                    <th>Nombre d{"'"}actes</th>
                    <th>Dépense</th>
                    <th>Remboursement obligatoire</th>
                    <th>Remboursement complémentaire</th>
                    <th>Remboursement autres organismes</th>
                </tr>
            </thead>
            <tbody>
                {flattenedData.map(item => (
                    <tr key={item.path} data-test="expenditures-per-item">
                        <td>{item.path}</td>
                        <td>{item.act_quantity}</td>
                        <td>{formatAsCurrency(item.expenditure || 0)}</td>
                        <td>{formatAsCurrency(item.ro_repayment || 0)}</td>
                        <td>{formatAsCurrency(item.rc_repayment || 0)}</td>
                        <td>
                            {formatAsCurrency(
                                item.other_organization_repayment || 0,
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </HiddenTable>
    );
};

ExpenditurePerItemDataTable.propTypes = {
    data: PropTypes.object,
};
