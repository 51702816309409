import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container } from '../../component/Container';
import { Breadcrumb } from './Breadcrumb';
import { SunburstChart } from './SunburstChart';
import getColorFromNode from './getColorFromNode';
import { filterTree } from './filterTree';
import { ExpenditurePerItemDataTable } from './ExpenditurePerItemDataTable';
import { getExpenditureItemFromLocation } from '../getExpenditureItemFromLocation';
import { generateQueryFiltersString } from '../../filters/FiltersProvider';
import { useDataProvider } from '../../service/DataProvider/hook';

export const categories = [
    { name: 'Autres prestations Santé', color: '#5687d1' },
    { name: 'Autres prestations Hors Santé', color: '#ac9faf' },
    { name: 'Honoraires médecins', color: '#7b615c' },
    { name: 'Dentaire', color: '#de783b' },
    { name: 'Hospitalisation', color: '#9467bd' },
    { name: 'Optique', color: '#6ab975' },
    { name: 'Pharmacie', color: '#e377c2' },
];

const PageContainer = styled(Container)({
    alignItems: 'center',
    flexDirection: 'column',

    '& > :first-child': {
        alignSelf: 'start',
    },
});

const getAncestors = (d, tillName) => {
    if (!d || d.data.name === tillName) {
        return [];
    }
    return [
        ...getAncestors(d.parent, tillName),
        { name: d.data.name, color: getColorFromNode(d) },
    ];
};

export const ExpenditurePerItemContext = React.createContext({});

export class ExpenditurePerItemView extends Component {
    static propTypes = {
        centerName: PropTypes.string,
        data: PropTypes.object,
        onItemSelected: PropTypes.func.isRequired,
    };

    state = {
        breadcrumb: [],
        focus: 'rc_repayment',
        overBreadCrumb: [],
        quantity: 0,
        selectedGroup: 'Tous',
        total: 0,
    };

    handleNodeSelected = node => {
        this.setState({
            breadcrumb: getAncestors(node),
            total: node ? node.value : 0,
        });
    };

    handleSelectNode = node => {
        const { data, onItemSelected } = this.props;
        const allowedItems = data.items.children.map(c => c.name);

        if (allowedItems.includes(node)) {
            onItemSelected(node);
        }

        if (node === data.items.name) {
            onItemSelected();
        }
    };

    handleExamineNode = node => {
        const newState = {
            overBreadCrumb: getAncestors(node, this.props.data.items.name),
            quantity: node ? node.value : 0,
        };
        this.setState(newState);
    };

    render() {
        const { centerName, data } = this.props;
        const {
            breadcrumb,
            focus,
            overBreadCrumb,
            quantity,
            selectedGroup,
            total,
        } = this.state;

        const width = (window.innerWidth || document.body.clientWidth) - 150;
        const computedHeight =
            (window.innerHeight || document.body.clientHeight) - 350;
        const height = computedHeight >= 450 ? computedHeight : 450;
        const hasValidData =
            data && data.items && Object.keys(data.items).length > 1;

        const items = hasValidData
            ? filterTree(data.items)({
                  group: selectedGroup,
              })
            : {};
        return (
            <ExpenditurePerItemContext.Provider
                value={{
                    breadcrumb,
                    expenditureItems: categories,
                    onSelectedItem: this.props.onItemSelected,
                }}
            >
                <PageContainer id="expenditures-per-item-page">
                    <Breadcrumb
                        items={breadcrumb}
                        focus={focus}
                        onSelectNode={this.handleSelectNode}
                        overItems={overBreadCrumb}
                        quantity={quantity}
                        total={total}
                    />
                    {hasValidData && (
                        <Fragment>
                            <SunburstChart
                                centerName={centerName || data.items.name}
                                focus={focus}
                                items={items}
                                height={height}
                                onExamineNode={this.handleExamineNode}
                                onNodeSelected={this.handleNodeSelected}
                                onSelectNode={this.handleSelectNode}
                                width={width / 2}
                            />
                            <ExpenditurePerItemDataTable data={data.items} />
                        </Fragment>
                    )}
                </PageContainer>
            </ExpenditurePerItemContext.Provider>
        );
    }
}

export const ExpenditurePerItem = ({ queryFilters, history, location }) => {
    const [centerName, setCenterName] = useState(
        getExpenditureItemFromLocation(location),
    );
    useEffect(() => setCenterName(getExpenditureItemFromLocation(location)));

    const [data, updateQueryFilters] = useDataProvider(
        '/api/provision-tree',
        {},
        queryFilters,
    );
    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);

    const navigateToItem = item => {
        history.push({
            pathname: location.pathname,
            search: generateQueryFiltersString({
                ...queryFilters,
                item,
            }),
        });
    };

    return (
        <ExpenditurePerItemView
            centerName={centerName}
            data={data}
            onItemSelected={navigateToItem}
        />
    );
};

ExpenditurePerItem.propTypes = {
    queryFilters: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
