import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Composition } from './Composition';
import { useDataProvider } from '../../service/DataProvider/hook';

const Container = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

export const FamilyComposition = ({ queryFilters }) => {
    const [data, updateQueryFilters] = useDataProvider(
        '/api/family-composition',
        [],
        queryFilters,
    );
    const [compositions, setCompositions] = useState(null);

    useEffect(() => {
        setCompositions(
            Object.keys(data).reduce(
                (acc, key) => [...acc, { name: key, value: data[key] }],
                [],
            ),
        );
    }, [data]);

    useEffect(() => {
        updateQueryFilters(queryFilters);
    }, [queryFilters]);

    if (!compositions) {
        return null;
    }
    return (
        <Container id="family-composition-page">
            {compositions.map(composition => (
                <Composition key={composition.name} {...composition} />
            ))}
        </Container>
    );
};

FamilyComposition.propTypes = {
    queryFilters: PropTypes.object,
};
