import { generateApiCall } from './tools';

export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const UPDATE_FETCH_URL = 'FETCH_URL';

export const reducer = (state, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            };
        case FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: action.payload.error,
            };
        case UPDATE_FETCH_URL:
            return {
                ...state,
                apiCall: generateApiCall(
                    action.payload.url,
                    action.payload.federation,
                    action.payload.queryFilters,
                ),
            };
        default:
            throw new Error();
    }
};
