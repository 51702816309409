import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { SunburstLegend } from './SunburstLegend';
import D3SunBurstChart from './d3SunburstChart';

const containerStyles = {
    display: 'flex',
};

export class SunburstChart extends Component {
    static propTypes = {
        items: PropTypes.object.isRequired,
        focus: PropTypes.string.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        centerName: PropTypes.string,
        selectedGroup: PropTypes.string,
        onSelectNode: PropTypes.func.isRequired,
        onExamineNode: PropTypes.func.isRequired,
        onNodeSelected: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { centerName, width, height, items, focus } = this.props;

        this.d3SunBurstChart = new D3SunBurstChart({
            width,
            height,
            element: this.chart,
            onHover: this.props.onExamineNode,
            onClick: this.props.onSelectNode,
            onRender: this.props.onNodeSelected,
            items,
            focus,
        });

        this.draw = this.d3SunBurstChart.draw;
        this.zoom = this.d3SunBurstChart.zoom;

        if (centerName) {
            this.zoom(centerName);
        }
    }

    componentWillUnmount() {
        this.draw = null;
        this.zoom = null;
        this.d3SunBurstChart.destroy();
        this.d3SunBurstChart = null;
    }

    shouldComponentUpdate(nextProps) {
        const { items, focus, selectedGroup, centerName } = nextProps;
        if (
            !isEqual(items, this.props.items) ||
            focus !== this.props.focus ||
            selectedGroup !== this.props.selectedGroup
        ) {
            this.draw(items, focus);
            return false;
        }

        if (centerName === this.props.centerName) {
            return false;
        }

        this.zoom(centerName);

        return false;
    }

    render() {
        return (
            <div style={containerStyles}>
                <SunburstLegend />
                <div
                    ref={elem => {
                        this.chart = elem;
                    }}
                />
            </div>
        );
    }
}
