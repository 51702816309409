import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Bar,
    BarChart,
    LabelList,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { theme } from '../../theme';

const TooltipContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.grey}`,
    whiteSpace: 'nowrap',
    padding: '1rem',
    boxShadow: `inset 1px 1px 1px ${theme.colors.grey}, 0px 2px 3px ${theme
        .colors.greyShadow}, 0px 2px 2px ${theme.colors.greyShadow2}`,
}));

const TooltipTitle = styled('h2')(({ theme }) => ({
    color: theme.colors.black,
}));
const Tip = styled('p')(({ color }) => ({
    color,
    span: {
        fontWeight: 'bold',
    },
}));

const gapFormatter = label =>
    label.toLocaleString('fr-FR', {
        style: 'percent',
    });

const getGapInString = (payload, key) => {
    const value = payload[`${key}Gap`];
    if (!value) {
        return '';
    }

    return `(${value > 0 ? '+' : ''}${gapFormatter(
        value,
    )} par rapport à ${payload.year - 1})`;
};

export class CustomTooltip extends Component {
    static propTypes = {
        payload: PropTypes.array,
        active: PropTypes.any,
    };

    render() {
        const { active, payload } = this.props;

        if (active && payload) {
            return (
                <TooltipContainer>
                    <TooltipTitle>
                        {payload[0].payload.monthLabel}{' '}
                        {payload[0].payload.year}
                    </TooltipTitle>

                    {payload.map(pl => (
                        <Tip key={pl.name} color={pl.color}>
                            <span>{pl.name}</span> : {pl.value}{' '}
                            {getGapInString(pl.payload, pl.dataKey)}
                        </Tip>
                    ))}
                </TooltipContainer>
            );
        }

        return null;
    }
}

const labelBase = css({
    fontSize: '1rem',
    fontWeight: 'bold',
});

const labelWhite = css({
    fill: theme.colors.white,
});

const labelConjoint = css({
    fill: theme.colors.blueDark,
});

const labelAdherent = css({
    fill: theme.colors.orange,
});

const labelEnfant = css({
    fill: theme.colors.blue,
});

export const MonthlyChart = ({ data, filter }) => (
    <ResponsiveContainer width="75%" height={500}>
        <BarChart data={data}>
            <XAxis dataKey="month" />
            <YAxis hide dataKey="total" />
            {!filter && <Tooltip content={<CustomTooltip />} />}
            {(!filter || filter === 'adherent') && (
                <Bar
                    dataKey="adherent"
                    stackId="a"
                    fill={theme.colors.orange}
                    name="Adhérents"
                >
                    {filter === 'adherent' && (
                        <LabelList
                            dataKey="adherentGap"
                            className={`${labelBase} ${labelAdherent}`}
                            position="top"
                            offset={10}
                            formatter={gapFormatter}
                        />
                    )}
                    {filter === 'adherent' && (
                        <LabelList
                            dataKey="adherent"
                            className={`${labelBase} ${labelWhite}`}
                            position="center"
                            offset={10}
                        />
                    )}
                </Bar>
            )}
            {(!filter || filter === 'conjoint') && (
                <Bar
                    dataKey="conjoint"
                    stackId="a"
                    fill={theme.colors.blueDark}
                    name="Conjoints"
                >
                    {filter === 'conjoint' && (
                        <LabelList
                            dataKey="conjointGap"
                            className={`${labelBase} ${labelConjoint}`}
                            position="top"
                            offset={10}
                            formatter={gapFormatter}
                        />
                    )}
                    {filter === 'conjoint' && (
                        <LabelList
                            dataKey="conjoint"
                            className={`${labelBase} ${labelWhite}`}
                            position="center"
                            offset={10}
                        />
                    )}
                </Bar>
            )}
            {(!filter || filter === 'enfant') && (
                <Bar
                    dataKey="enfant"
                    stackId="a"
                    fill={theme.colors.blue}
                    name="Enfants"
                >
                    {filter === 'enfant' && (
                        <LabelList
                            dataKey="enfantGap"
                            className={`${labelBase} ${labelEnfant}`}
                            position="top"
                            offset={10}
                            formatter={gapFormatter}
                        />
                    )}
                    {filter === 'enfant' && (
                        <LabelList
                            dataKey="enfant"
                            className={`${labelBase} ${labelWhite}`}
                            position="center"
                            offset={10}
                        />
                    )}
                </Bar>
            )}
        </BarChart>
    </ResponsiveContainer>
);

MonthlyChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            month: PropTypes.string.isRequired,
            monthLabel: PropTypes.string.isRequired,
            adherent: PropTypes.number,
            adherentGap: PropTypes.any,
            conjoint: PropTypes.number,
            conjointGap: PropTypes.any,
            enfant: PropTypes.number,
            enfantGap: PropTypes.any,
            total: PropTypes.number.isRequired,
        }),
    ),
    filter: PropTypes.string,
};
