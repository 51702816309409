import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AppBarContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const AppBar = styled('div')(({ theme, inverted }) => ({
    backgroundColor: inverted ? theme.colors.blueDark : theme.colors.white,
    borderBottom: `1px solid ${theme.colors.grey}`,
    color: inverted ? theme.colors.white : theme.colors.black,
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
}));

const AppBarContent = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    margin: '0 auto',
    maxWidth: `${theme.maxWidth}px`,
    width: '100%',
}));

export const NavBar = ({ children, inverted }) => (
    <AppBarContainer id="navBar">
        <AppBar inverted={inverted}>
            <AppBarContent>{children}</AppBarContent>
        </AppBar>
    </AppBarContainer>
);

NavBar.propTypes = {
    children: PropTypes.node.isRequired,
    inverted: PropTypes.bool,
};
