import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link as RRLink } from 'react-router-dom';

import { Link } from '../component/Link';
import { DistributionChart } from '../beneficiaries/distribution/DistributionChart';
import { getDistributionDataFromApiResponse } from '../beneficiaries/distribution/getDistributionDataFromApiResponse';
import { RepaymentItem } from './RepaymentItem';
import { Coverage } from './Coverage';
import { formatAsCurrency } from '../service/format';
import { useDataProvider } from '../service/DataProvider/hook';

const Sections = styled('div')({
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1rem',
});

const Section = styled('div')({
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem',
});

const CoverageContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',

    '& > span': {
        color: theme.colors.black,
        marginLeft: '1rem',
        textTransform: 'uppercase',
    },
}));

const HomeButton = styled(Link)({
    alignSelf: 'center',
    borderRadius: '2rem 2rem',
    fontSize: '1.125rem',
    fontWeight: 500,
    padding: '1rem 2rem',
    textTransform: 'uppercase',
});

const PrimaryButton = styled(HomeButton)(({ theme }) => ({
    color: theme.colors.blueDark,
    marginBottom: '2rem',
}));

const RepaymentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '3rem',

    '& > *': {
        flexBasis: '33.33%',
    },
});

const RepaymentItemLink = styled(RRLink)({
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
});

const EXPENDITURE_ITEMS = {
    'Honoraires médecins': 0,
    Pharmacie: 1,
    Hospitalisation: 2,
    Optique: 3,
    Dentaire: 4,
    'Autres prestations Santé': 5,
    'Autres prestations Hors Santé': 6,
};

const clearExpenses = data =>
    Object.keys(data.expenses)
        .filter(key => Object.keys(EXPENDITURE_ITEMS).includes(key))
        .map(key => ({
            name: key,
            ...data.expenses[key],
        }))
        .sort((a, b) => {
            const itemA = EXPENDITURE_ITEMS[a.name];
            const itemB = EXPENDITURE_ITEMS[b.name];

            return itemA - itemB;
        });

export const Home = () => {
    const defaultData = {
        expenses: {},
        beneficiaries: {},
    };
    const [data] = useDataProvider('/api/summary', defaultData);
    const [
        {
            complementaryRepayment,
            totalCoverage,
            totalBeneficiaries,
            expenses,
            beneficiaries,
        },
        setState,
    ] = useState({
        complementaryRepayment: 0,
        totalCoverage: 0,
        totalBeneficiaries: 0,
        expenses: clearExpenses(defaultData),
        beneficiaries: getDistributionDataFromApiResponse(
            defaultData.beneficiaries,
        ),
    });

    useEffect(() => {
        setState({
            complementaryRepayment: data.expenses.total,
            totalCoverage: data.expenses.total_coverage,
            totalBeneficiaries: data.beneficiaries.total,
            expenses: clearExpenses(data),
            beneficiaries: getDistributionDataFromApiResponse(
                data.beneficiaries,
            ),
        });
    }, [data]);

    return (
        <Sections id="home-page">
            <Section>
                <PrimaryButton to="/beneficiaries">
                    {`Bénéficiaires = ${totalBeneficiaries || ''} personnes`}
                </PrimaryButton>
                <DistributionChart data={beneficiaries} />
            </Section>
            <Section>
                <HomeButton to="/expenditures">
                    {`Remboursement complémentaire = ${formatAsCurrency(
                        complementaryRepayment,
                    )}`}
                </HomeButton>
                <CoverageContainer>
                    <Coverage value={totalCoverage} />
                    <span>Taux de couverture</span>
                </CoverageContainer>
                <RepaymentsContainer>
                    {expenses.map(expense => (
                        <RepaymentItemLink
                            data-test={`item-link-${expense.name}`}
                            key={expense.name}
                            to={`/expenditures?item=${expense.name}`}
                        >
                            <RepaymentItem expense={expense} />
                        </RepaymentItemLink>
                    ))}
                </RepaymentsContainer>
            </Section>
        </Sections>
    );
};
