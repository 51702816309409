import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const Filters = styled('div')(({ isLoading, theme }) => ({
    padding: '0',
    cursor: isLoading ? 'wait' : 'default',
    color: isLoading ? theme.colors.greyShadow2 : theme.colors.black,
    marginTop: 0,
    fontSize: '0.9rem',
    display: 'flex',
    flexDirection: 'column',
}));
const FilterTitle = styled('div')(({ theme }) => ({
    color: theme.colors.greyShadow2,
    padding: '0.5rem 0.2rem 0.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 600,
    transition: `border-left-color ${theme.transitionDuration} ease-out`,
    '&.active': {
        borderLeftColor: theme.colors.orange,
        color: theme.colors.black,
    },
    textTransform: 'uppercase',
    '& span:first-child': {
        width: '130px',
    },
}));
const ToggleArrow = styled(ExpandMoreIcon)(({ theme }) => ({
    cursor: 'pointer',
    transition: `transform ${theme.transitionDuration}`,
    '&.close': {
        transform: 'rotate(-90deg)',
    },
}));
const FilterList = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    transition: `max-height ${theme.transitionDuration} ease-out`,
    height: 'auto',
    maxHeight: '2000px',
    '&.close': {
        maxHeight: 0,
    },
}));
const FilterItem = styled('div')(({ theme }) => ({
    padding: '0.5rem 1rem 0.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    textTransform: 'capitalize',
    transition: `border-left-color ${theme.transitionDuration} ease-out`,
    '&.active': {
        borderLeftColor: theme.colors.orange,
    },
}));

const CheckBoxOn = styled(CheckBoxIcon)({
    cursor: 'pointer',
});
const CheckBoxOff = styled(CheckBoxOutlineBlankIcon)({
    cursor: 'pointer',
});

class Company extends Component {
    static propTypes = {
        company: PropTypes.object,
        filterName: PropTypes.string.isRequired,
        filters: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        onAddFilter: PropTypes.func.isRequired,
        onRemoveFilter: PropTypes.func.isRequired,
        onResetCompany: PropTypes.func.isRequired,
        onUnselectCompany: PropTypes.func.isRequired,
    };

    state = { isOpen: false };

    handleToggle = () =>
        this.setState({
            isOpen: !this.state.isOpen,
        });

    render() {
        const {
            company,
            filterName,
            filters,
            isLoading,
            onAddFilter,
            onRemoveFilter,
            onResetCompany,
            onUnselectCompany,
        } = this.props;
        return (
            <Filters key={company.code} id={company.code} isLoading={isLoading}>
                <FilterTitle
                    className={filters.length > 0 ? 'normal' : 'active'}
                >
                    <span>{company.label}</span>
                    <span>
                        {filters.length < company.children.length ? (
                            <CheckBoxOn
                                onClick={() =>
                                    onUnselectCompany(
                                        filterName,
                                        company.code,
                                        company.children.map(
                                            filter => filter.code,
                                        ),
                                    )
                                }
                            />
                        ) : (
                            <CheckBoxOff
                                onClick={() =>
                                    onResetCompany(filterName, company.code)
                                }
                            />
                        )}
                        <ToggleArrow
                            onClick={this.handleToggle}
                            className={this.state.isOpen ? 'open' : 'close'}
                        />
                    </span>
                </FilterTitle>
                <FilterList className={this.state.isOpen ? 'open' : 'close'}>
                    {company.children &&
                        company.children.length > 0 &&
                        company.children.map(filter => (
                            <FilterItem
                                key={`${company.code}*${filter.code}`}
                                className={
                                    filters.length > 0 &&
                                    filters.includes(filter.code)
                                        ? 'normal'
                                        : 'active'
                                }
                            >
                                {filter.label.toLowerCase()}
                                {filters &&
                                filters.includes(`${filter.code}`) ? (
                                    <CheckBoxOff
                                        onClick={() =>
                                            onRemoveFilter(
                                                filterName,
                                                `${company.code}*${
                                                    filter.code
                                                }`,
                                            )
                                        }
                                    />
                                ) : (
                                    <CheckBoxOn
                                        onClick={() =>
                                            !filters ||
                                            filters.length !==
                                                company.children.length
                                                ? onAddFilter(
                                                      filterName,
                                                      `${company.code}*${
                                                          filter.code
                                                      }`,
                                                  )
                                                : false
                                        }
                                    />
                                )}
                            </FilterItem>
                        ))}
                </FilterList>
            </Filters>
        );
    }
}

export class FiltersTree extends Component {
    static propTypes = {
        data: PropTypes.array,
        filterName: PropTypes.string.isRequired,
        filterState: PropTypes.array,
        isLoading: PropTypes.bool,
        onAddFilter: PropTypes.func.isRequired,
        onRemoveFilter: PropTypes.func.isRequired,
        onResetCompany: PropTypes.func.isRequired,
        onUnselectCompany: PropTypes.func.isRequired,
    };

    render() {
        const {
            data,
            filterName,
            filterState,
            isLoading,
            onAddFilter,
            onRemoveFilter,
            onResetCompany,
            onUnselectCompany,
        } = this.props;

        const sortedFilters = filterState
            ? filterState.reduce((acc, filter) => {
                  const [companyCode, productCode] = filter.split('*');
                  if (!acc[companyCode]) {
                      acc[companyCode] = [];
                  }
                  acc[companyCode].push(productCode);

                  return acc;
              }, {})
            : {};

        return (
            <Fragment>
                {data && data.length &&
                    data.map(company => (
                        <Company
                            company={company}
                            filterName={filterName}
                            filters={sortedFilters[company.code] || []}
                            isLoading={isLoading}
                            key={`corp_${company.code}`}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                            onResetCompany={onResetCompany}
                            onUnselectCompany={onUnselectCompany}
                        />
                    ))}
            </Fragment>
        );
    }
}
