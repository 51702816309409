export const getGeograpyDataFromApiResponse = ({ name, ...data }) =>
    data
        ? Object.keys(data).reduce(
              (acc, key) =>
                  key === name
                      ? acc
                      : {
                            ...acc,
                            [key]: data[key],
                        },
              {},
          )
        : {};
