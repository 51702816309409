import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    LabelList,
    Tooltip,
} from 'recharts';

import { theme } from '../../theme';
import { formatAsCurrency } from '../../service/format';

const labelBase = css({
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
});

const cpLabel = css({
    fill: theme.colors.blueDark,
});

const acLabel = css({
    fill: theme.colors.orange,
});

const obLabel = css({
    fill: theme.colors.blue,
});

const valueFormatter = value => formatAsCurrency(value);

export const ExpenditurePerBeneficiaryChart = ({ data, filter }) => (
    <ResponsiveContainer width="75%" height={300}>
        <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 20, right: 40, left: 40, bottom: 20 }}
        >
            <XAxis
                type="number"
                dataKey="total"
                padding={{ right: 30 }}
                tick={false}
            />
            <YAxis
                dataKey="name"
                type="category"
                padding={{ top: 20, bottom: 10, right: 10 }}
            />
            {!filter && <Tooltip formatter={valueFormatter} />}
            <Bar
                type="number"
                dataKey="ob"
                stackId="a"
                fill={theme.colors.blue}
                name="Régime obligatoire"
            >
                {filter === 'ob' && (
                    <LabelList
                        className={`${labelBase} ${obLabel}`}
                        position="right"
                        dataKey="ob"
                        offset={10}
                        formatter={valueFormatter}
                    />
                )}
            </Bar>
            <Bar
                dataKey="cp"
                stackId="a"
                fill={theme.colors.blueDark}
                name="Régime complémentaire"
            >
                {filter === 'cp' && (
                    <LabelList
                        className={`${labelBase} ${cpLabel}`}
                        position="right"
                        dataKey="cp"
                        offset={10}
                        formatter={valueFormatter}
                    />
                )}
            </Bar>
            <Bar
                dataKey="ac"
                stackId="a"
                fill={theme.colors.orange}
                name="Reste à charge"
            >
                {filter === 'ac' && (
                    <LabelList
                        className={`${labelBase} ${acLabel}`}
                        position="right"
                        dataKey="ac"
                        offset={10}
                        formatter={valueFormatter}
                    />
                )}
            </Bar>
        </BarChart>
    </ResponsiveContainer>
);

ExpenditurePerBeneficiaryChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.any,
            ac: PropTypes.number,
            cp: PropTypes.number,
            ob: PropTypes.number,
        }),
    ),
    filter: PropTypes.string,
};
