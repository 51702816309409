import React from 'react';

export const Icon = () => (
    <svg
        width="43px"
        height="52px"
        viewBox="0 0 43 52"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Pharmacie</title>
        <g
            id="ACCUEIL"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            transform="translate(-1321.000000, -291.000000)"
        >
            <g
                id="noun_287531_cc"
                transform="translate(1321.000000, 291.000000)"
                fillRule="nonzero"
            >
                <g id="Group">
                    <path
                        d="M0.78475,15.64225 C4.015125,19.8076667 12.0615,23.5733333 21.5,23.5733333 C30.9385,23.5733333 38.9838,19.8076667 42.214175,15.64225 L0.78475,15.64225 Z"
                        id="Shape"
                        fill="#000000"
                    />
                    <rect
                        id="Rectangle-path"
                        fill="#000000"
                        x="19.9649"
                        y="23.53325"
                        width="3.0702"
                        height="3.99533333"
                    />
                    <path
                        d="M22.71475,44.63225 C22.71475,44.63225 22.182625,44.8900833 21.501075,44.8911667 C20.64215,44.8911667 20.286325,44.5618333 20.286325,44.5618333 L20.286325,32.9875 L22.71475,32.9875 L22.71475,44.63225 Z"
                        id="Shape"
                        fill="#000000"
                    />
                    <path
                        d="M23.63065,38.8678333 C23.63065,38.8678333 27.95215,38.8418333 27.95215,43.22825 C27.95215,46.04275 25.551675,48.6091667 21.69565,48.17475 C17.622475,47.7143333 17.43435,45.5715 15.2306,46.1716667 C14.487775,46.3731667 13.737425,45.75675 14.128725,45.2605833 C14.61785,44.6398333 16.85385,44.0125833 18.54375,44.77525 C21.21405,45.981 22.57285,45.617 23.221075,45.54875 C25.110925,45.3515833 26.263325,42.8285 23.63065,42.4439167 L23.63065,38.8678333 Z"
                        id="Shape"
                        fill="#F29400"
                    />
                    <path
                        d="M19.33065,38.8678333 L19.33065,42.5901667 C19.33065,42.5901667 7.461575,43.22825 7.461575,34.8215833 C7.461575,25.831 18.0772,28.30425 19.428475,28.36925 C25.2582,28.64875 33.956025,28.574 34.7225,22.18125 C34.7225,22.18125 35.87275,21.7685 36.50485,21.45325 C36.937,21.2376667 37.636825,20.9094167 38.686025,20.20525 C38.686025,20.20525 40.019025,31.8738333 23.3533,32.1208333 C19.054375,32.18475 11.01875,30.22825 11.01875,34.9678333 C11.01875,40.08875 19.33065,38.8678333 19.33065,38.8678333"
                        id="Shape"
                        fill="#F29400"
                    />
                    <path
                        d="M17.22365,47.2636667 C17.22365,47.2636667 21.5,51.168 27.456575,47.4348333 L31.2567,50.1973333 L31.2567,51.168 L11.7433,51.168 L11.7433,49.9546667 L17.22365,47.2636667 Z"
                        id="Shape"
                        fill="#000000"
                    />
                    <path
                        d="M21.69565,0.832 C14.97045,0.821166667 12.5388,5.61058333 12.5388,8.42183333 C12.5388,8.42183333 15.969125,10.24725 18.9716,9.26575 C20.591625,8.73708333 22.22025,7.75125 23.7876,5.90633333 C23.7876,5.90633333 30.253725,5.70916667 33.6604,14.6705 L38.19045,14.6705 C38.19045,14.6705 35.64915,0.85475 21.69565,0.832 M16.960275,5.97025 C16.54425,5.97025 16.2067,5.63008333 16.2067,5.21083333 C16.2067,4.79158333 16.54425,4.45141667 16.960275,4.45141667 C17.3763,4.45141667 17.71385,4.79158333 17.71385,5.21083333 C17.71385,5.63008333 17.3763,5.97025 16.960275,5.97025"
                        id="Shape"
                        fill="#F29400"
                    />
                </g>
            </g>
        </g>
    </svg>
);
