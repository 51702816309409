import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';

const Federations = styled('h2')(({ theme }) => ({
    alignItems: 'center',
    borderRight: `1px solid ${theme.colors.black}`,
    color: theme.colors.blueDark,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    margin: '0 2rem 0 0',
    textTransform: 'uppercase',
    ...theme.spacing.navBarChildren,
    paddingRight: '1rem',
    width: '220px',
    minWidth: '220px',
    maxWidth: '220px',
}));

const FederationSelectionLink = styled(Link)(({ theme }) => ({
    color: theme.colors.black,
    textDecoration: 'none',
    fontSize: '0.6rem',
    textTransform: 'none',
    fontStyle: 'italic',
    marginTop: '0.5rem',
}));

const BreadcrumbLink = styled(Link)(({ theme }) => ({
    color: theme.colors.black,
    fontSize: '1.3125rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    ...theme.spacing.navBarChildren,
    textTransform: 'uppercase',
    textDecoration: 'none',
    margin: 0,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',

    '&:hover': {
        color: theme.colors.blueDark,
    },
}));

const Title = styled('h2')(({ theme, last }) => ({
    color: theme.colors.black,
    flexGrow: last ? 1 : 0,
    fontSize: '1.3125rem',
    fontWeight: 600,
    lineHeight: '1.16667em',
    margin: '0',
    ...theme.spacing.navBarChildren,
    textTransform: 'uppercase',
}));

const User = styled('h2')(({ theme }) => ({
    alignItems: 'center',
    color: theme.colors.black,
    display: 'flex',
    fontSize: '1.3125rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    margin: '0',
    padding: '1.5rem 2rem 1.5rem 0',
    position: 'relative',
}));

const Avatar = styled(PersonIcon)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.colors.black,
    borderRadius: '50%',
    color: theme.colors.white,
    display: 'inline-flex',
    fontSize: '20px',
    height: '40px',
    justifyContent: 'center',
    marginLeft: '0.5rem',
    width: '40px',
}));

const Logout = styled(ExitToAppIcon)(({ theme }) => ({
    alignItems: 'center',
    color: theme.colors.black,
    display: 'inline-flex',
    fontSize: '20px',
    height: '30px',
    justifyContent: 'center',
    marginLeft: '0.5rem',
    width: '30px',
    cursor: 'pointer',
}));

const BreadcrumbSeparator = styled(KeyboardArrowRightIcon)({
    margin: '0 1rem',
});

export const DefaultNavBar = ({
    federation,
    title,
    user,
    onLogout,
    onRemoveFederation,
    pathName,
}) => {
    const isHomePage = pathName === '/';
    const isFederationsPage = pathName === '/federations';
    return (
        <Fragment>
            {federation && !isFederationsPage && (
                <Federations id="main-title">
                    {federation.label}
                    {onRemoveFederation && (
                        <FederationSelectionLink
                            id="federation-selection-link"
                            to="/federations"
                            onClick={() => {
                                onRemoveFederation();
                                return false;
                            }}
                        >
                            Changer de fédération
                        </FederationSelectionLink>
                    )}
                </Federations>
            )}
            {!isHomePage && !isFederationsPage && (
                <Fragment>
                    <BreadcrumbLink to="/">Accueil</BreadcrumbLink>
                    <BreadcrumbSeparator />
                </Fragment>
            )}
            {Array.isArray(title) ? (
                title.map((titleItem, index) => (
                    <Fragment key={titleItem}>
                        {index > 0 && <BreadcrumbSeparator />}
                        {titleItem.path ? (
                            <BreadcrumbLink to={titleItem.path}>
                                {titleItem.label}
                            </BreadcrumbLink>
                        ) : (
                            <Title last={index === title.length - 1}>
                                {titleItem}
                            </Title>
                        )}
                    </Fragment>
                ))
            ) : (
                <Title isHome={isHomePage} last>
                    {title}
                </Title>
            )}
            <User>
                {user.first_name} {user.last_name} <Avatar />
            </User>
            {onLogout && <Logout id="logout-button" onClick={onLogout} />}
        </Fragment>
    );
};

DefaultNavBar.propTypes = {
    federation: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    user: PropTypes.object.isRequired,
    onLogout: PropTypes.func,
    onRemoveFederation: PropTypes.func,
    pathName: PropTypes.string.isRequired,
};

DefaultNavBar.defaultProps = {
    user: {},
};
