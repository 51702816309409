import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { formatAsPercentage } from '../service/format';

const CoverageRect = styled('rect')(({ theme }) => ({
    fill: 'none',
    fillOpacity: '1',
    stroke: theme.colors.orange,
    shapeRendering: 'crispEdges',
}));

const CoverageArrow1 = styled('path')({
    fill: '#ffffff',
    stroke: '#ffffff',
    shapeRendering: 'crispEdges',
});

const CoverageArrow2 = styled('path')(({ theme }) => ({
    fill: 'none',
    stroke: theme.colors.orange,
    shapeRendering: 'crispEdges',
}));

const CoverageValue = styled('span')(({ theme, withArrow }) => ({
    color: theme.colors.black,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, ${withArrow ? '-80%' : '-60%'})`,
}));

const CoverageContainer = styled('div')(({ theme }) => ({
    color: theme.colors.black,
    position: 'relative',
}));

export const Coverage = ({ withArrow, value }) => (
    <CoverageContainer className="coverage">
        <svg width="60px" height={withArrow ? '40px' : '30px'}>
            <CoverageRect
                width="60px"
                height="30px"
                x="0"
                y="0"
                strokeWidth="2"
            />
            {withArrow && (
                <Fragment>
                    <CoverageArrow1 d="m 25,30 10,0" strokeWidth="2" />
                    <CoverageArrow2
                        className="arrow"
                        d="m 25,30 5,5 5,-5"
                        strokeWidth="2"
                    />
                </Fragment>
            )}
        </svg>
        <CoverageValue data-test="coverage" withArrow={withArrow}>
            {formatAsPercentage(value)}
        </CoverageValue>
    </CoverageContainer>
);

Coverage.propTypes = {
    withArrow: PropTypes.bool,
    value: PropTypes.number,
};
