import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import { getExpenditureItemFromLocation } from '../expenditures/getExpenditureItemFromLocation';

export const getTitle = (location, federation, lastImportDate) => {
    if (location.pathname.startsWith('/beneficiaries')) {
        return 'Effectifs';
    }

    if (location.pathname.startsWith('/expenditures')) {
        if (location.search) {
            const item = getExpenditureItemFromLocation(location);

            if (item) {
                let path = location.pathname;

                if (location.pathname.includes('/kalivia')) {
                    path = '/expenditures';
                }

                return [{ label: 'Dépenses', path }, item];
            }
        }

        return 'Dépenses';
    }

    if (location.pathname.startsWith('/federations')) {
        return 'Sélectionnez une fédération';
    }

    if (federation) {
        return lastImportDate
            ? `Situation mutuelle au ${format(lastImportDate, 'DD/MM/YYYY', {
                  locale: frLocale,
              })}`
            : 'Situation mutuelle';
    }
    return '';
};
