import React from 'react';
import styled from '@emotion/styled';

const Title = styled('h2')(({ theme }) => ({
    fontSize: '1.6125rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    margin: 0,
    textAlign: 'center',
    width: '100%',
    ...theme.spacing.navBarChildren,
}));

export const AuthenticationNavBar = () => <Title>Bienvenue sur DataViz</Title>;
