import React from 'react';
import styled from '@emotion/styled';
import { Container } from './component/Container';

const NotFoundContainer = styled(Container)(({ theme }) => ({
    color: theme.colors.black,
    flexDirection: 'column',
    textAlign: 'center',
}));
export const NotFound = () => (
    <NotFoundContainer>
        <h1>Cette page n'existe pas.</h1>
        <p>(Ou n'a pas encore été implémentée)</p>
    </NotFoundContainer>
);
