import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { WorkingFilter } from './WorkingFilter';
import { FiltersTree } from './FiltersTree';
import { UserContext } from '../application';

export const FiltersContainer = styled('div')(({ theme }) => ({
    borderRight: `1px solid ${theme.colors.black}`,
    color: theme.colors.blueDark,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    margin: '0',
    ...theme.spacing.navBarChildren,
    paddingRight: '1rem',
    paddingTop: '4rem',
    width: '220px',
    minWidth: '220px',
    maxWidth: '220px',
}));

export const Filters = ({
    filters,
    isLoading,
    onAddFilter,
    onAddSingleFilter,
    onRemoveFilter,
    onResetFilter,
    onSelectFirstFilter,
    role,
    onResetCompany,
    onUnselectCompany,
}) => {
    const context = useContext(UserContext);
    const data = context.federation
        ? context.filters.find(
              filter => filter.code === context.federation.code,
          )
        : {};

    return (
        <FiltersContainer id="filters-container">
            <WorkingFilter
                filterState={filters.working}
                onResetFilter={onResetFilter}
                onAddSingleFilter={onAddSingleFilter}
                isLoading={isLoading}
            />
            <FiltersTree
                data={data.children || []}
                filterName="companies"
                filterState={filters.companies}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                onResetCompany={onResetCompany}
                onUnselectCompany={onUnselectCompany}
                isLoading={isLoading}
            />
        </FiltersContainer>
    );
};

Filters.propTypes = {
    filters: PropTypes.object,
    isLoading: PropTypes.bool,
    onAddFilter: PropTypes.func.isRequired,
    onAddSingleFilter: PropTypes.func.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onResetFilter: PropTypes.func.isRequired,
    onSelectFirstFilter: PropTypes.func.isRequired,
    onUnselectCompany: PropTypes.func.isRequired,
    onResetCompany: PropTypes.func.isRequired,
};
