import React from 'react';
import PropTypes from 'prop-types';

import {
    Bar,
    BarChart,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { css } from 'emotion';

import { theme } from '../../theme';

const labelBase = css({
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
});

const adherentLabel = css({
    fill: theme.colors.orange,
});

const conjointLabel = css({
    fill: theme.colors.blueDark,
});

const enfantLabel = css({
    fill: theme.colors.blue,
});

export const DistributionChart = ({ data }) => (
    <ResponsiveContainer width="100%" height={500}>
        <BarChart
            barCategoryGap="15%"
            barGap="10%"
            data={data}
            layout="vertical"
            margin={{ top: 0, right: 90, left: 90, bottom: 0 }}
        >
            <XAxis type="number" tick={false} />
            <YAxis type="category" tick={false} />
            <Bar
                dataKey="adherent"
                fill={theme.colors.orange}
                layout="vertical"
            >
                <LabelList
                    className={`${labelBase} ${adherentLabel}`}
                    position="left"
                    content={() => 'Adhérents'}
                    offset={10}
                />
                <LabelList
                    className={`${labelBase} ${adherentLabel}`}
                    position="right"
                    dataKey={'adherent'}
                    formatter={value =>
                        (value === 0.001 ? 0 : value).toString()
                    }
                />
            </Bar>
            <Bar
                dataKey="conjoint"
                fill={theme.colors.blueDark}
                layout="vertical"
            >
                <LabelList
                    className={`${labelBase} ${conjointLabel}`}
                    position="left"
                    content={() => 'Conjoints'}
                    offset={10}
                />
                <LabelList
                    className={`${labelBase} ${conjointLabel}`}
                    position="right"
                    dataKey={'conjoint'}
                    formatter={value =>
                        (value === 0.001 ? 0 : value).toString()
                    }
                />
            </Bar>
            <Bar dataKey="enfant" fill={theme.colors.blue} layout="vertical">
                <LabelList
                    className={`${labelBase} ${enfantLabel}`}
                    position="left"
                    content={() => 'Enfants'}
                    offset={10}
                />
                <LabelList
                    className={`${labelBase} ${enfantLabel}`}
                    position="right"
                    dataKey={'enfant'}
                    formatter={value =>
                        (value === 0.001 ? 0 : value).toString()
                    }
                />
            </Bar>
        </BarChart>
    </ResponsiveContainer>
);

DistributionChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            adherent: PropTypes.number,
            conjoint: PropTypes.number,
            enfant: PropTypes.number,
        }),
    ),
};
