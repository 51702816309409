import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { getLabel } from '../../home/RepaymentItem';
import { ExpenditurePerItemContext } from './ExpenditurePerItem';

import { Socket } from '../../component/Socket';
import { Icon as AutreIcon } from '../../home/Autres';
import { Icon as AutreHSIcon } from '../../home/Autres HS';
import { Icon as HonoraireIcon } from '../../home/Honoraire';
import { Icon as DentaireIcon } from '../../home/Dentaire';
import { Icon as HospitalIcon } from '../../home/Hospitalisation';
import { Icon as OptiqueIcon } from '../../home/Optique';
import { Icon as PharmaIcon } from '../../home/Pharmacie';

const LegendContainer = styled('div')({
    display: 'flex',
    margin: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
});

const ExpenseContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    color: props.color,
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    transition: 'color .2s ease-in',
    cursor: 'pointer',
    '&:hover': {
        color: props.hover || 'inherit',
    },
    '& span': {
        width: '100px',
    },
}));

const IconContainer = styled('div')({
    alignSelf: 'center',
    display: 'flex',
    height: '3rem',
    position: 'relative',
    width: '3rem',
    marginRight: '1rem',
    '& > svg:first-child': {
        height: '3.4rem',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '3rem',
        color: 'red',
    },

    '& > svg:last-child': {
        height: '1.5rem',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        width: '1.5rem',
    },
});

const ExpenseIcon = ({ label }) => {
    switch (label) {
        case 'Autres':
            return <AutreIcon />;
        case 'Autres HS':
            return <AutreHSIcon />;
        case 'Honoraire':
            return <HonoraireIcon />;
        case 'Dentaire':
            return <DentaireIcon />;
        case 'Hospitalisation':
            return <HospitalIcon />;
        case 'Optique':
            return <OptiqueIcon />;
        case 'Pharmacie':
            return <PharmaIcon />;
        default:
            return null;
    }
};
const Expense = React.memo(({ activeExpense, expense, onSelectedItem }) => {
    const color =
        activeExpense && activeExpense !== expense.name
            ? '#dcdee2'
            : expense.color;
    const navigationTarget =
        activeExpense === expense.name ? null : expense.name;

    return (
        <ExpenseContainer
            color={color}
            hover={expense.color}
            onClick={event => {
                event.stopPropagation();
                onSelectedItem(navigationTarget);
            }}
        >
            <IconContainer>
                <Socket color={color} width="6" className="background" />
                <ExpenseIcon label={getLabel(expense.name)} />
            </IconContainer>
            <span>{expense.name}</span>
        </ExpenseContainer>
    );
});

export const SunburstLegend = () => {
    const { breadcrumb, expenditureItems, onSelectedItem } = useContext(
        ExpenditurePerItemContext,
    );
    const [activeExpenditure, setActiveExpenditure] = useState(null);

    useEffect(() => {
        if (breadcrumb.length > 1 && activeExpenditure !== breadcrumb[1].name) {
            setActiveExpenditure(breadcrumb[1].name);
        }
        if (breadcrumb.length <= 1 && activeExpenditure !== null) {
            setActiveExpenditure(null);
        }
    }, [breadcrumb]);

    return (
        <LegendContainer>
            {expenditureItems.map(expenditure => (
                <Expense
                    activeExpense={activeExpenditure}
                    expense={expenditure}
                    key={getLabel(expenditure.name)}
                    onSelectedItem={onSelectedItem}
                />
            ))}
        </LegendContainer>
    );
};
