import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { HiddenTable } from '../../component/HiddenTable';
import { formatAsCurrency } from '../../service/format';

export const KaliviaDataTable = ({ data }) => (
    <HiddenTable>
        <thead>
            <tr>
                <th>Type</th>
                <th>Quantitée</th>
                <th>Dépense</th>
                <th>Reste à charge</th>
            </tr>
        </thead>
        {Object.keys(data).map(segment => (
            <tbody key={segment} data-test={`kalivia-${segment}`}>
                {[
                    'Verres simples',
                    'Verres complexes',
                    'Verres très complexes',
                    'Montures',
                ].map((typeName, index) => {
                    const type = data[segment][index];
                    const kalivia =
                        type.length && type[0]
                            ? type[0]
                            : { quantity: 0, expenditure: 0, leftover: 0 };
                    const others =
                        type.length && type[1]
                            ? type[1]
                            : { quantity: 0, expenditure: 0, leftover: 0 };

                    return (
                        <Fragment key={typeName}>
                            <tr>
                                <td>{typeName} - Kalivia</td>
                                <td data-test="quantity">{kalivia.quantity}</td>
                                <td data-test="expenditure">
                                    {formatAsCurrency(kalivia.expenditure)}
                                </td>
                                <td data-test="leftover">
                                    {formatAsCurrency(kalivia.leftover)}
                                </td>
                            </tr>
                            <tr>
                                <td>{typeName} - Hors Kalivia</td>

                                <td data-test="quantity">{others.quantity}</td>
                                <td data-test="expenditure">
                                    {formatAsCurrency(others.expenditure)}
                                </td>
                                <td data-test="leftover">
                                    {formatAsCurrency(others.leftover)}
                                </td>
                            </tr>
                        </Fragment>
                    );
                })}
            </tbody>
        ))}
    </HiddenTable>
);

KaliviaDataTable.propTypes = {
    data: PropTypes.object,
};
